import {action, observable} from "mobx";
import {flowSignHasExpired} from "../components/main/service";
import {APP_INIT_STATUS} from "../constants";

const globalStore = observable({
  initLoading: APP_INIT_STATUS.DONE,
  configUrls: {},
  userInfo: {}, // 用户信息
  pdfFlowInfo: {}, // 来自文档的流程信息
  serverPdfInfo: {}, // 来自服务端的流程信息
  esignPerson: {},
  esignOrg: {},
  sealInfo: null
})


// 更新app初始化状态
export function updateInitLoadingStatus(status) {
  if (!Object.values(APP_INIT_STATUS).includes(status)) {
    throw new Error('invalid status');
  }
  action(() => {
    globalStore.initLoading = status;
  })();
}

// 更新config url
export function updateConfigUrls(urls) {
  action(() => {
    globalStore.configUrls = urls;
  })();
}

// 更新用户状态
export function updateUserStatus(status) {
  action(() => {
    globalStore.userStatus = status;
  })();
}

// 更新服务端流程签信息
export function updateServerPdfInfo (info) {
  info.ward_type = parseInt(info.ward_type);
  action(() => {
    globalStore.serverPdfInfo = info;
    globalStore.flowExpired = flowSignHasExpired(info);
  })();
}
// 更新文档流程签信息
export function updatePdfFlowInfo (info) {
  action(() => {
    globalStore.pdfFlowInfo = info;
  })();
}

// 更新用户信息
export function updateUserInfo(info) {
  action(() => {
    globalStore.userInfo = info;
  })();
}

export function updateESignPersonInfo(info) {
  action(() => {
    globalStore.esignPerson = info;
  })()
}

export function updateESignOrgInfo(info) {
  action(() => {
    globalStore.esignOrg = info;
  })()
}

export function updateSealInfo(sealInfo) {
  action(() => {
    globalStore.sealInfo = sealInfo;
  })()
}

export function setStoreCache() {
  localStorage.setItem('globalStore', JSON.stringify(globalStore));
}

export function cleanStoreCache() {
  localStorage.removeItem('globalStore');
}

export function getStoreFromCache() {
  let store = localStorage.getItem('globalStore')
  if (store) {
    return JSON.parse(store);
  } else {
    return null;
  }
}

export default globalStore;
