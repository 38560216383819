import './app.less'
import {initApp} from "./service";
import {useEffect} from "react";
import {initErrorMonitorTool} from "./utils/env";

function App({ children }) {

  useEffect(() => {
    initErrorMonitorTool();
    initApp()
  }, [])

  // children 是将要会渲染的页面
  return children
}

export default App
