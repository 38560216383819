/**
 * 表单域标识
 * @namespace Constants.FIELD_FLAG
 */

/**
 * This field is read only, no editing allowed.
 * @memberOf Constants.FIELD_FLAG
 * @default 0x01
 */
export const FIELD_READONLY = 0x01;
/**
 * This field is required when submit.
 * @memberOf Constants.FIELD_FLAG
 * @default 0x02
 */
export const FIELD_REQUIRED = 0x02;
/**
 * Don't export the field when submitting. Not supported for now.
 * @memberOf Constants.FIELD_FLAG
 * @default 0x04
 */
export const FIELD_NOEXPORT = 0x04;

/**
 * Additional flags for radio button fields.
 */

/**
 * For radio button only: if set, one radio button has to be selected at any time;
 * Otherwise, when the selected radio button is clicked, it will be turned off,
 * (leaving no radio button selected at this time).
 * @memberOf Constants.FIELD_FLAG
 * @default 0x100
 */
export const RADIO_NOTOGGLEOFF = 0x100;
/**
 * If set, radio buttons with same value in a field will be turned on or off
 * in unison (either all one, or all off). If cleared, all buttons are
 * mutually exclusive.
 * @memberOf Constants.FIELD_FLAG
 * @default 0x200
 */
export const RADIO_UNISON = 0x200;

/**
 * Additional flags for text fields.
 */

/**
 *  Multiple lines.
 *  @memberOf Constants.FIELD_FLAG
 *  @default 0x100
 */
export const TEXT_MULTILINE = 0x100;
/**
 * This is a password field. Password shouldn't be displayed or exported.
 * @memberOf Constants.FIELD_FLAG
 * @default 0x200
 */
export const TEXT_PASSWORD = 0x200;
/**
 * (PDF 1.4) If set, the text entered in the field represents the pathname of a file whose
 * contents are to be submitted as the value of the field.
 * @memberOf Constants.FIELD_FLAG
 * @default 0x400
 */
export const TEXT_FILESELECT = 0x400;
/**
 * (PDF 1.4) If set, text entered in the field is not spell-checked.
 * @memberOf Constants.FIELD_FLAG
 * @default 0x800
 */
export const TEXT_NOSPELLCHECK = 0x800;
/**
 * Do not scroll (vertically for multi-line field, or horizontally for
 * single-line field). If the field is full, no more text is accepted.
 * @memberOf Constants.FIELD_FLAG
 * @default 0x1000
 */
export const TEXT_NOSCROLL = 0x1000;
/**
 * If set, this field is arranged in a number of equally spaced positions
 * ("combs"), the number of positions is determined by MaxLen parameter.
 * @memberOf Constants.FIELD_FLAG
 * @default 0x2000
 */
export const TEXT_COMB = 0x2000;
/**
 * @(PDF 1.5) If set, the value of this field should be represented as a rich text string
 * @memberOf Constants.FIELD_FLAG
 * @default 0x4000
 */
export const TEXT_RICHTEXT = 0x4000;

/**
 * Additional flags for combo box fields.
 */

/**
 * (Meaningful only when FORMCHOICE_COMBO flag is set): if set, the combo box
 * includes an editable text control, otherwise, it's only a drop list.
 * @memberOf Constants.FIELD_FLAG
 * @default 0x100
 */
export const COMBO_EDIT = 0x100;

/**
 * Additional flags for list box fields.
 */

/**
 * If set, more than one items can be selected.
 * @memberOf Constants.FIELD_FLAG
 * @default 0x100
 */
export const LIST_MULTISELECT = 0x100;

/**
 * Additional flags for choice fields.
 */

/**
 * If set, the field  s option items should be sorted alphabetically
 * @memberOf Constants.FIELD_FLAG
 * @default 0x200
 */
export const CHOICE_SORT = 0x200;
/**
 * @(PDF 1. If set, the new value is committed as soon as a selection is
 * made with the pointing device.
 * @memberOf Constants.FIELD_FLAG
 * @default 0x400
 */
export const CHOICE_COMMITONSELCHANGE = 0x400;
