const GET_FLOW_INFO_FROM_PDF = 'getFlowInfoFromPDF';
const UPDATE_FLOW_FIELD_STATUS = 'updateFlowFieldStatus';
const GET_CURRENT_SIGN_ITEM = 'getCurrentSignItem'
const SHOW_AUTH_DIALOG = 'showAuthDialog';
const AUTH_SUCCESS = 'authSuccess'
const AUTH_FAIL = 'authFail'
const PDF_FIELD_DISABLED = 'pdfFieldDisabled'
const CLICK_FIELD_ITEM = 'clickFieldItem'
const ACTIVE_FIELD = 'activeField'
const UPDATE_WILL_AUTH_DIALOG_VISIBLE = 'updateWillAuthDialogVisible'
const START_OUT_SIGN = 'startOutSign'
const START_IN_SIGN = 'startInSign'
const SHOW_SEAL_ACTION_SHEET = 'showSealActionSheet'
const UPDATE_DOC_INFO = 'updateDocInfo'
const HANDLE_CACHE_BEFORE_WILL_AUTH = 'handleCacheBeforeWillAuth';
const NORMAL_SIG_FIELD_CREATE_SIGN_DATE_FIELD = 'normalSigFieldCreateSignDateField'
const SHOW_HAND_DRAW_PAD = 'showHandDrawPad';
const UPDATE_SEALS_LIST = 'updateSealsList';
// const OPEN_SEAL_ACTION_SHEET = 'openSealActionSheet';
const COMPLETE_USER_INFO = 'completeUserInfo';
const AUTH_CHECK_END = 'authCheckEnd';
const UPDATE_STAMP = 'updateStamp';
const SELECT_SEAL_STAMP = 'selectSealStamp';
const FILL_DATE_FIELD = 'fillDateField';
const BATCH_SET_FIELD_VALUE = 'batchSetFieldValue';
const ALL_FIELD_HAS_FILLED = 'allFieldHasFilled';
const SHOW_TOAST_MSG = 'showToastMsg';

export {
  GET_FLOW_INFO_FROM_PDF,
  GET_CURRENT_SIGN_ITEM,
  SHOW_AUTH_DIALOG,
  AUTH_SUCCESS,
  AUTH_FAIL,
  PDF_FIELD_DISABLED,
  CLICK_FIELD_ITEM,
  ACTIVE_FIELD,
  UPDATE_FLOW_FIELD_STATUS,
  UPDATE_WILL_AUTH_DIALOG_VISIBLE,
  START_OUT_SIGN,
  START_IN_SIGN,
  SHOW_SEAL_ACTION_SHEET,
  UPDATE_DOC_INFO,
  HANDLE_CACHE_BEFORE_WILL_AUTH,
  NORMAL_SIG_FIELD_CREATE_SIGN_DATE_FIELD,
  SHOW_HAND_DRAW_PAD,
  UPDATE_SEALS_LIST,
  // OPEN_SEAL_ACTION_SHEET,
  COMPLETE_USER_INFO,
  AUTH_CHECK_END,
  UPDATE_STAMP,
  SELECT_SEAL_STAMP,
  FILL_DATE_FIELD,
  BATCH_SET_FIELD_VALUE,
  ALL_FIELD_HAS_FILLED,
  SHOW_TOAST_MSG
}


class EventManager {
  constructor() {
    this.events = {};
  }

  on(eventName, callback) {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }
    this.events[eventName].push(callback);
  }

  emit(eventName, ...args) {
    const callbacks = this.events[eventName];
    if (!Array.isArray(callbacks)) {
      return;
    }
    callbacks.forEach(callback => {
      callback && callback(...args);
    })
  }

  off(eventName, callback) {
    if (!callback) {
      return;
    }
    const callbacks = this.events[eventName];
    if (!Array.isArray(callbacks)) {
      return;
    }
    this.events[eventName] = callbacks.filter(item => item !== callback);
  }

  once(eventName, callback) {
    const fn = function () {
      callback.apply(this, arguments);
      this.off(eventName, fn);
    }.bind(this);
    this.on(eventName, fn);
  }
}



const obj = new EventManager();
export default obj;
