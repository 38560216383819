export const testBaseUrl = 'https://pheecws-devcn.connectedpdf.com';
export const stgBaseUrl = 'https://pheecws-stgcn.connectedpdf.com';
export const baseUrl = 'https://pheecws.foxitsoftware.cn';

export const VCONSOLE_URL = 'https://unpkg.com/vconsole@latest/dist/vconsole.min.js'

export const LOCAL_HOST_KEY = 'localhost'
export const DEVAZK8S_HOST_KEY = 'devcn'
export const STGCN_HOST_KEY = 'stgcn'
export const PRODUCTION_HOST_KEY = 'foxitsoftware'

export const ENV = {
  LOCAL: 'localhost',
  DEVAZK8S: 'devcn',
  STGCN: 'stgcn',
  PRODUCTION: 'production',
  UNKNOW: 'unknow'
}

export const APP_INIT_STATUS = {
  FLOW_HAS_DELETE: -2,
  NOT_EXIST_FLOW_INFO: -1,
  DONE: 0,
  SERVER_LOADING: 1,
  SDK_LOADING: 2,
  RESOLVE_LOADING: 3,
}

export const USER_STATUS = {
  NO_LOGIN: 0,
  HAS_LOGIN: 1,
  NO_READ_PERMISSION: 2,
  HAS_PERSONAL_AUTHENTICATION: 3,
  HAS_ORG_AUTHENTICATION: 4
}

export const WARD_TYPE = {
  OUT: 0,
  IN: 1
}

export const AUTH_PLUGIN_ROUTE = '/auth'

export const SEAL_TYPE = {
  OUT_ORG: 'out_org',
  OUT_PERSON: 'out_person',
  IN_PERSON: 'in_person',
  OUT_ORG_VALUE: 1,
  OUT_PERSON_VALUE: 0,
  IN_PERSON_VALUE: 0,
}

export const FLOW_ORDERED = {
  ORDERED: 1, // 完全有序
  PARTIAL: 2, // 部分有序
  UNORDERED: 3, // 完全无序
}

export const FIELD_STATUS = {
  NOT_FILLED: 0,
  HAS_SET_AP: 1,
  HAS_FILLED: 2
}

export const REDIRECT_FROM = {
  WILL_AUTH: 'willAuth'
}

export const RECIPIENT_ITEM_STATUS = {
  HAS_FINISHED: '4'
}

export const FOXIT_FIELD_TYPE = {
  // 0 普通签名域；1 签署日期签名域； 2 参与人自己创建的签名域 3:日期域 4:标题域
  NORMAL_SIG: 0,
  SIGN_DATE: 1,
  FREE_RECT: 2,
  DATE: 3,
  TITLE: 4,
}

export const WILL_AUTH_REDIRECT_STATUS = {
  READY_CACHE: 'readyCache',
  FINISH_CACHE: 'finishCache'
}

export const FLOW_STATUS = {
  "PROCESSING": {
    key: [0, 1],
    label: '签署中'
  },
  "FINISHED": {
    key: [2],
    label: '已完成'
  },
  "CANCELED": {
    key: [3],
    label: '已撤销'
  },
  "EXPIRED": {
    key: [4],
    label: '已过期'
  },
  "REFUSED": {
    key: [5],
    label: '已拒签'
  },
  "INVALIDING": {
    key: 6,
    label: '作废中'
  },
  "INVALID": {
    key: 7,
    label: '已作废'
  }
}

export const SIMPLE_AD_MARKETING_ACTIVITIES_ROUTE = 'ad'
export const AD_MARKETING_ACTIVITIES_ROUTE = 'pages/ad-marketing-activities/ad-marketing-activities'

export const NO_FLOW_PAGES = [`/${SIMPLE_AD_MARKETING_ACTIVITIES_ROUTE}`]

export const WILL_AUTH_STATUS = {
  INIT: 0,
  SUCCESS: 1,
  FAIL: 2
}

export const AUTH_CHECK_PURPOSE = {
  FLOW_CHECK: 0,
  COMPLETE_EMAIL_PHONE: 1
}


export const ERROR_CODE = {
  NOT_EXIST_FLOW_INFO: `INIT_ERROR_${APP_INIT_STATUS.NOT_EXIST_FLOW_INFO}`
}

export const SIGN_DATE_FORMAT = {
  'FORMAT1': 1, // yyyy/MM/dd
  'FORMAT2': 2, // yyyy-MM-dd
  'FORMAT3': 3, // yyyy年MM月dd日
  'FORMAT4': 4, // yyyy/MM/dd HH:mm
}

export const LEFT_PANEL_TYPE = {
  'FLOW': {
    key: 1,
    label: "签署流程"
  },
  'REJECT': {
    key: 2,
    label: "拒绝签署"
  },
  'CONFIG': {
    key: 3,
    label: "签署设置"
  },
}
