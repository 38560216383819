import {sessionCache} from "@/utils/storage";

const USER_AGENT = navigator.userAgent.toLowerCase();
// export const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(USER_AGENT);
export const isIOS = /ipad|iphone|ipod/.test(USER_AGENT);
export const isAndroid = /android/.test(USER_AGENT);
export const isBlackBerry = /blackberry/.test(USER_AGENT);
export const isWebOS = /webos/.test(USER_AGENT);
export const isKindle = /silk|kindle/.test(USER_AGENT);
export const isMiPad = /(MI(.*|\s*)PAD).*/i.test(USER_AGENT);
export const isXiaomi = !isMiPad && /(xiaomi).*/i.test(USER_AGENT);
export const isAliPayApp = /(AlipayClient).*/i.test(USER_AGENT);

export const isMobile =
  /mobile/.test(USER_AGENT) ||
  isIOS ||
  isAndroid ||
  isBlackBerry ||
  isWebOS ||
  isKindle;

export const isHuaWeiP40 = /ana-an00/.test(USER_AGENT);
export const isRealMe = /rmx2072/.test(USER_AGENT);

export const fixMobileBrowserBottomToolBarHeight = () => {
  const realScreenSafeAreaHeight = getSafeAreaHeight();
  let height = realScreenSafeAreaHeight
  const rootEle = document.querySelector('#app');
  rootEle.style.height = `calc(100vh - ${height}px)`; // 修复移动端浏览器底部工具栏高度
  rootEle.style.overflow = 'hidden';
  rootEle.style.minHeight = 'auto';

  requestAnimationFrame(() => {
    const taroPageEle = document.querySelector('.taro_page');
    taroPageEle && (taroPageEle.style.height = `calc(100vh - ${height}px)`);
    taroPageEle && (taroPageEle.style.overflow = `hidden`);
  })
  sessionCache.setSafeAreaHeight(height);

  const setHeightFn = (name, height) => {
    const ele = document.querySelector(name);
    if (ele) {
      ele.style.height = `calc(100vh - ${height}px)`;
    }
  }
  setHeightFn('#main', height)
  return height;
}

export const setEleHeight = (name, height) => {
  const ele = document.querySelector(name);
  ele && (ele.style.height = `calc(100vh - ${height}px)`);
}

export function setEleSafeAreaHeight(name) {
  const safeAreaHeight = sessionCache.getSafeAreaHeight();
  if (safeAreaHeight) {
    setEleHeight(name, safeAreaHeight);
  }
}

export function getSafeAreaHeight() {
  // 创建一个测试元素
  const testElement = document.createElement('div');
  testElement.style.height = '100vh'; // 设置高度为视口高度
  testElement.style.visibility = 'hidden'; // 隐藏元素
  document.body.appendChild(testElement); // 将元素添加到body中
  // 获取元素可见高度
  const visibleHeight = document.documentElement.clientHeight;
  // 获取元素实际高度
  const actualHeight = testElement.getBoundingClientRect().height;
  // 计算底部地址栏高度
  const bottomBarHeight = actualHeight - visibleHeight;
  // 移除测试元素
  document.body.removeChild(testElement);
  return bottomBarHeight;
}
