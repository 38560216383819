import {request, showModal} from '@tarojs/taro';
import qs from "qs";
import {sessionCache} from "../utils/storage";
// import {loginAction} from "@/models/loginModel";
import otelTool from "@/utils/otel";


class HttpRequest {

  constructor(config = {}) {
    this.forEsign = config.forEsign;
  }

  baseOptions({ url, data, params, headers = {}, responseType, method }) {
    return new Promise((resolve, reject) => {
      let mixHeaders = {
        ...headers,
      }
      if (this.forEsign) {
        mixHeaders['x-client-from'] = 5;
        mixHeaders = {...mixHeaders, ...otelTool.getTraceHeaders()}
      }
      const option = {
        url: params ? `${url}?${qs.stringify(params)}` : url,
        data, // body传参
        method,
        timeout:50000,
        header: mixHeaders,
        success: (res) => {
          const result = res.data;
          if (result && result.code === 0 ||  result && result.ret === 0) {
            resolve(result.data);
          } else {
            if (result && (result.code || result.ret)) {
              const code = result.code || result.ret;
              if ([600026, 210007, 600002, 110001].includes(code)) {
                showModal({
                  title: '提示',
                  content: '登录过期或状态错误，请重新登录',
                  success: function (res) {
                    sessionCache.removeToken();
                    window.location.reload();
                    if (res.confirm) {
                      // loginAction();
                    } else if (res.cancel) {
                    }
                  }
                })
              }
            }
            resolve(result)
          }
        },
        fail: (err) => {
          reject(err);
        }
      };
      if (responseType) {
        option.responseType = responseType
      }
      return request(option);
    })
  }

  get({url, data, params, headers, responseType }) {
    return this.baseOptions({ url, data, params, headers, responseType, method: "GET" });
  }

  post({url, data, params, headers, responseType}) {
    return this.baseOptions({ url, data, params, headers, responseType, method: "POST" });
  }

  put({url, data, params, headers, responseType}) {
    return this.baseOptions({ url, data, params, headers, responseType, method: "PUT" });
  }

  delete({url, data, params, headers, responseType}) {
    return this.baseOptions({ url, data, params, headers, responseType, method: "DELETE" });
  }

}

const commonHttpClient = new HttpRequest({ forEsign: false })
const eSignHttpClient = new HttpRequest({ forEsign: true })

export { commonHttpClient, eSignHttpClient }
