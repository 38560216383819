import { eSignHttpClient as httpRequest } from './util'

import {getEnterpriseId, getSupplierId, getToken, handleFLowDeleteStatus, resolveInnerSealSize} from "../service";
import globalStore from "../store";
import {isAliPayApp} from "../utils/device";
import otelTool from "@/utils/otel";


const outSupplierId =  getSupplierId();
const inSupplierId = getSupplierId(false);

function _getUrl() {
  return globalStore.configUrls.eSignCNUrl
}

// 获取签署流程信息
export function getSignFlowInfo({processId, cc}) {
  const params = {
    accessToken: getToken(),
    processId,
    resultVer: 2
  }
  if (cc) {
    params.cc = 1
  }
  const url = _getUrl() + "/api/sign-process/info";
  return httpRequest.get({
    url,
    params
  }).then(res => {
    if (!res) {
      return Promise.reject();
    } else if (typeof res.code === 'number' && res.code === 610153) { // 对于抄送接口异常的场景，如 流程被撤销/删除，则按抄送人为空返回
      handleFLowDeleteStatus()
      return Promise.reject()
    } else {
      return res;
    }
  })
}

export function downloadPdf({ docId }) {
  const params = {
    docId,
  };
  const url = _getUrl() + '/api/file/download';
  return httpRequest.get({ url, params, responseType: 'arraybuffer'})
}

// 对内 获取印章列表
export async function getInnerSealList() {
  const otelRes = await otelTool.startCommonSpan(`request-innerSealList`)
  const params = {
    accessToken: getToken(),
    enterpriseId: getEnterpriseId(),
  };
  const url = `${_getUrl()}/api/inner/seal/list`;
  return httpRequest.get({ url, params }).then( data => {
    const { lists } = data;
    const seals = [];
    if (lists) {
      for (let i = 0; i < lists.length; i++) {
        const item = lists[i];
        const base64 = `data:image/${item['picture_type']};base64,${item['picture_base64']}`;
        delete item['picture_base64'];
        item.base64 = base64;
        const { width, height } = resolveInnerSealSize(item);
        item.width = width;
        item.height = height;
        seals.push(item);
      }
    }
    return seals;
  }).finally(() => {
    otelRes.span.end();
  })
}

export function getNewSubscription() {
  const params = {
    accessToken: getToken(),
  }
  if(getEnterpriseId()){
    params.tenantId = getEnterpriseId()
  }
  return httpRequest.get({
    url: `${_getUrl()}/api/user/new-subscription`,
    params,
    timeout: 20000
  });
}



// 获取企业订阅/用户授权状态
export function checkAuthApi(isOuter = false){
  const product = !isOuter ? 'internal' : 'external';
  const token = getToken();
  const enterpriseId = getEnterpriseId();
  const url = `${_getUrl()}/api/user/enterprise-auth?accessToken=${token}&enterpriseId=${enterpriseId}&product=${product}`;
  return httpRequest.get({url}).then(res => {
    if (res) {
      if (!res.code) {
        return 1;
      } else if (res.code === 600027) {
        return -2;
      } else if (res.code === 600028) {
        return -1;
      }
    }
    return -3;
  }).catch(() => {
    return -3;
  });
}


export function getSAMLResponse() {
  const casUrl = globalStore.configUrls.casApiUrl;
  const url = `${casUrl}/cas/saml/assertion`;
  const params = {
    access_token: getToken()
  }
  return httpRequest.get({ url, params })
}

// 检测当前saml用户，是否归属当前企业
export function checkEnterpriseUser() {
  const params = {
    accessToken: getToken(),
    enterpriseId: getEnterpriseId()
  }

  const url = _getUrl() + "/api/user/enterprise-user";
  return httpRequest.get({url, params}).then(res => {
    const result = res.data;
    if (result) {
      if (result.code === 0) {
        if (result.data.userType === 2) {
          return true;
        } else {
          return false;
        }
      }
    }
    return Promise.reject(res);
  });
}

// 获取当前账户所属租户信息
// 获取当前账户的过期时间和剩余份数
// wardType 1:对内；0：对外
export function getSubscription({wardType}) {
  const params = {
    accessToken: getToken(),
    tenantId: getEnterpriseId(),
    wardType: wardType === 1 ? 1 : 0
  };
  const url = `${_getUrl()}/api/user/subscription`;
  return httpRequest.get({url, params});
}

// 上传文档
export function uploadSignedFile({
                                   fileName,
                                   blob,
                                   processId,
                                   recipientOrder,
                                   version
                                 }) {
  const forms = new FormData();
  forms.append('processId', processId);
  forms.append('recipientOrder', recipientOrder);
  forms.append('accessToken', getToken());
  forms.append('file', blob, fileName);
  forms.append('version', version)

  const url = _getUrl() + '/api/file/upload';
  return fetch(url, { // taro request不支持发送form表单数据
    method: "POST",
    headers: {
      'x-client-from': 5
    },
    body: forms
  })
  .then(res => res.json())
    .then(res => {
      console.log(res)
      if (res.code === 0) {
        return res.data;
      } else {
        return Promise.reject(res);
      }
    }).catch(err => {
        return Promise.reject(err)
    });
}

// 对内 签署
export async function innerSignFileDigest({
                                            hash,
                                            processId,
                                            from = 0,
                                            recipientOrder,
                                            signerId,
                                            signId,
                                            sealInfo,
                                            version
                                          }) {
  const otelRes = await otelTool.startCommonSpan(`inner-sign-getHash`);
  const params = {
    accessToken: getToken(),
    enterpriseId: getEnterpriseId(),
    signId,
    version,
    supplierId: inSupplierId,
  };
  const url = _getUrl() + '/api/inner/signature/pdf-hash';
  const data = {
    hash,
    signerId,
    from,
    processId,
    recipientOrder,
    sealInfo,
  };
  return httpRequest.post({ url, data, params }).finally(() => {
    otelRes.span.end();
  })
}


// 获取意愿认证URL
export async function getWillAuthUrl({ accountId, redirectUrl }) {

  const otelRes = await otelTool.startCommonSpan(`outer-request-willAuth`);

  const params = {
    accessToken: getToken(),
    supplierId: outSupplierId,
  };
  const data = {
    bizType: 'SIGN',
    isPCRedirect: true,
    accountId,
    redirectUrl,
    willTypes: ['FACE_ZHIMA_XY', 'FACE_TECENT_CLOUD_H5', 'CODE_SMS'],
    willDefaultType: 'CODE_SMS',
  };
  if(isAliPayApp) {
    data.willTypes = ['FACE_FACE_LIVENESS_RECOGNITION', 'CODE_SMS'];
    data.willDefaultType = 'CODE_SMS';
  }
  const url = `${_getUrl()}/api/will-auth/create-auth`;
  return httpRequest.post({ url, data, params }).finally(() => {
    otelRes.span.end();
  })
}

// 查询意愿认证结果
export function getWillAuthResult({ bizId }) {
  const params = {
    accessToken: getToken(),
    supplierId: outSupplierId,
    bizType: 'SIGN',
    bizId,
  };
  const url = `${_getUrl()}/api/will-auth/query-result`;
  return httpRequest.get({ url, params })
}


// 存证
export function addEvidenceForSign({signId, operatorFlowId, signerFlowId, signLogId, oriHash, signedHash, willAuthId, version}) {
  const params = {
    accessToken: getToken(),
    supplierId: outSupplierId,
    signId,
    version
  }
  const url = _getUrl() + "/api/evidence/add-evidence";
  const data = {operatorFlowId, signerFlowId, signLogId, oriHash, signedHash, willAuthId};
  return httpRequest.post({
    url,
    data,
    params
});
}


// 获取签名hash
// from: 0, // 0 online 1phantom win  2phantom mac 3 android
export async function signFileDigest({    sealInfo, version,
                                 hash, operatorId, signerId, willingnessBizId, chapterType,
                                 tenantId, docId, from = 0, signId, processId, recipientOrder
                               }) {
  const params = {
    accessToken: getToken(),
    supplierId: willingnessBizId ? outSupplierId : inSupplierId, // 1易签宝，2沃通(发起时需要)
    signId,
    version
  }
  const url = _getUrl() + "/api/signature/pdf-hash";
  const data = {
    hash,
    operatorId,
    signerId,
    willingnessBizId,
    chapterType,
    tenantId,
    docId,
    from,
    processId,
    recipientOrder,
    sealInfo
  }
  const otelRes = await otelTool.startCommonSpan(`outer-sign-getHash`)
  return httpRequest.post({
    url,
    data,
    params,
    timeout: 20000
  }).finally(() => {
    otelRes.span.end();
  })
}

// 获取抄送信息
export function getProcessCCInfo({ processId }) {
  const params = {
    accessToken: getToken(),
    processId
  }
  const url = `${_getUrl()}/api/sign-process/carbon-copy`;
  return httpRequest.get({ url, params }).then(res => {
    if (!res || typeof res.code === 'number' && res.code !== 0) { // 对于抄送接口异常的场景，如 流程被撤销/删除，则按抄送人为空返回
      return []
    } else {
      return res;
    }
  })
}

export function getTenantName({token, tenantId}) {
  const params = {
    accessToken: token,
    tenantId: tenantId,
    wardType: 0
  };
  const url = `${_getUrl()}/api/user/subscription`;
  return httpRequest.get({url, params});
}

// 拒签
export function refuseSign({ processId, recipientOrder, rejectReason}) {
  const formData = new FormData();
  formData.append('processId', processId);
  formData.append('recipientOrder', recipientOrder);
  formData.append('rejectReason', rejectReason);
  const url = `${_getUrl()}/api/sign-process/reject?accessToken=${getToken()}`;
  return fetch(url, { // taro request不支持发送form表单数据
    method: "POST",
    headers: {
      'x-client-from': 5
    },
    body: formData
  })
  .then(res => res.json())
  .then((res) => {
    if (res.code === 0) {
      return Promise.resolve();
    } else {
      return Promise.reject(res);
    }
  });
}
