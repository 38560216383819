import React from 'react';
import BaseComponent from "./BaseComponent";
import {FORMAT} from "./ADDITIONAL_ACTION_TYPE";
import './index.less'
import EsignCnCalendar from "./datePicker";
import './datePicker/index.css'
import eventManager, {FILL_DATE_FIELD, PDF_FIELD_DISABLED} from "@/utils/event";
import {parseDateFormat} from "../utils";
import fieldsData from "@/components/main/flowData";
import {sessionCache} from "@/utils/storage";


export default class TextComponent extends BaseComponent{
  createDom () {
    super.createDom();
    const eLabel = this.eLabel;
    const field = this.field;
    const labelId = `eSignCN-label-${this.widget.eSignAnnotKey}`;
    const domId = `eSignCN-dom-${this.widget.eSignAnnotKey}`;
    this.type = 'text';
    this.hasSet = false;
    this.eLabel.id = labelId;
    if (field[FORMAT]) {
      const dateFormat = parseDateFormat(field[FORMAT].javascript);
      if (dateFormat) {
        this.type = 'date';
        this.eDom = this.createDateDom(domId)
        this.addEle2Window(true); // 不应该放在这里，要考虑 与text field 在一处处理
      } else {
        this.eDom = this.createTextDom(domId, field.value)
      }
    } else {
      this.eDom = this.createTextDom(domId, field.value)
    }
    if (this.disable) {
      this.eDom.disabled = true;  // mobile端 用户点击别人的文本域要禁止唤起键盘
    }

    this.hideDom();
    eLabel.appendChild(this.eDom);
  }

  createDateDom(domId) {
    let eDom = document.createElement('div');
    eDom.classList.add('date-input');
    eDom.id = domId;
    this.eLabel.style.justifyContent = 'center';
    this.eLabel.dataValue = 'esign-date-picker'
    const calendar = new EsignCnCalendar(this.eLabel, this.handleSelectDate.bind(this))
    this.eLabel.onclick = (e) => {
      if(!this.disable) {
        calendar.showDatePicker();
      }
    }
    eDom.style.opacity = '0';
    eDom.showPicker = () => {
      if(!this.disable) {
        calendar.showDatePicker();
      }
    }
    this.initSetDate()
    return eDom;
  }

  createTextDom(domId, value) {
    let eDom = document.createElement('input');
    eDom.classList.add('fx-field-dom');
    eDom.id = domId;
    eDom.value = value;
    eDom.type = 'text';
    return eDom;
  }

  initSetDate() {
    const field = fieldsData.getField(this.widget.eSignAnnotKey)
    if (field && field.tempState.value) {
      this.valueChanged(field.tempState.value)
    }
  }

  handleSelectDate(val) {
    if (val) {
      const dateFormat = this._parseDateFormat(this.field[FORMAT].javascript)
      val = this._formatDate(val, dateFormat);
    } else {
      val = ''
    }
    eventManager.emit(FILL_DATE_FIELD, { date: val, key: this.widget.eSignAnnotKey })
    this.valueChanged(val);
    this.hasSet = true;
  }

  handleFieldDisabled(data) {
    super.handleFieldDisabled(data);
  }

  handleCheckFieldDisabledData() {
    const dataStr = sessionStorage.getItem('PDF_FIELD_DISABLED_DATA');
    if (dataStr) {
      const data = JSON.parse(dataStr);
      this.handleFieldDisabled(data);
    }
  }

  bindEvent () {
    super.bindEvent();

    const eDom = this.eDom;
    const focusFn = (e) => {
      if (this.disable) {
        return;
      }
      if (eDom.type === 'date') {
        return;
      }
      // 设置字体大小
      const len = this.field.value.replace(/[\u0391-\uFFE5]/g,"aa").length;
      const eLabel = this.eLabel;
      const width = parseFloat(eLabel.style.width);
      const height = parseFloat(eLabel.style.height) - 12;
      let fontSize = width / len;
      if (fontSize > height) {
        fontSize = height;
      }

      fontSize = Math.floor(2*height/3);

      console.info("🍭  ~ file:TextComponent method:focusFn line:56 -----", fontSize, height);

      eDom.style.fontSize =  fontSize + 'px';
      eDom.style.height = height + 'px';
      eDom.style.lineHeight = fontSize + 'px';
      eDom.style.padding = '5px';
      this.eLabel.style.backgroundColor = 'transparent';
      this.showDom();
    };
    const blurFn = (e) => {
      if (this.eDom.type === 'date') {
        return;
      }
      this.eLabel.style.backgroundColor = this.signCNOptions.color + '28'; // 透明度
      this.hideDom();
    };
    const changeFn = () => {
      if (this.disable) {
        return;
      }
      let value = eDom.value;
      if (eDom.type === 'date') {
        const dateFormat = this._parseDateFormat(this.field[FORMAT].javascript)
        value = this._formatDate(value, dateFormat);
      }
      this.valueChanged(value);
      if (this.type === 'text') {
        fieldsData.updateField(this.widget.eSignAnnotKey, { value })
      }
      if (value) {
        this.eLabel.style.backgroundColor = 'transparent';
      } else {
        this.eLabel.style.backgroundColor = this.signCNOptions.color + '28'; // 透明度
      }
    };

    const clickFn = (e) => {
      if (this.disable) {
        e.preventDefault(); // mobile端 用户点击别人的文本域要禁止唤起键盘
        return;
      }
    };

    const stopFn = () => {
      eDom.click()
    }

    const handleListenSetDateEvent = ({key, date}) => {
      if (this.type === 'date') {
        if (key !== this.widget.eSignAnnotKey ) {
          const signConfig = sessionCache.getSignConfig();
          const field = fieldsData.getField(this.widget.eSignAnnotKey);
          if (signConfig.batchSetDate && (!field.tempState.hasSetToPdf)) {
            this.valueChanged(date)
            this.hasSet = true;
            fieldsData.updateField(this.widget.eSignAnnotKey, { value: date })
          }
        } else {
          fieldsData.updateField(this.widget.eSignAnnotKey, { value: date, isPrimaryField: true })
        }
      }
    }


    eDom.addEventListener('focus', focusFn.bind(this));
    this.releases.push(() => eDom.removeEventListener('focus', focusFn.bind(this)));
    eDom.addEventListener('blur', blurFn.bind(this));
    this.releases.push(() => eDom.removeEventListener('blur', blurFn.bind(this)));
    eDom.addEventListener('change', changeFn.bind(this));
    this.releases.push(() => eDom.removeEventListener('change', changeFn.bind(this)));
    this.eLabel.addEventListener('click', clickFn.bind(this));
    this.releases.push(() => this.eLabel.removeEventListener('click', clickFn.bind(this)));

    this.nameSpan && this.nameSpan.addEventListener('click', stopFn.bind(this));
    this.releases.push(() => this.nameSpan && this.nameSpan.removeEventListener('click', stopFn.bind(this)));

    eventManager.on(PDF_FIELD_DISABLED, this.handleFieldDisabled.bind(this))
    eventManager.on(FILL_DATE_FIELD, handleListenSetDateEvent)
    this.handleCheckFieldDisabledData()

  }

  _formatDate(value, fmt) {
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      return '';
    }
    let ret;
    const opt = {
      "y+": date.getFullYear().toString(),
      "m+": (date.getMonth() + 1).toString(),
      "d+": date.getDate().toString(),
      "H+": date.getHours().toString(),
      "M+": date.getMinutes().toString(),
      "S+": date.getSeconds().toString()
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      };
    };
    return fmt;
  }

  _parseDateFormat (javascript) {
    if (javascript.indexOf('AFDate_Format') !== -1) {
      const reg = /AFDate_Format(?:Ex)?\("(.*?)"\)/;
      const match = javascript.match(reg);
      if (match && match.length === 2) {
        return match[1].trim();
      }
    }
    return '';
  }


}
