import localforage from 'localforage'
import globalStore, {updateInitLoadingStatus} from "../../store";
import {
  checkAuthApi,
  checkEnterpriseUser,
  downloadPdf,
  getSAMLResponse,
  getSubscription,
} from "../../request/esign";
import {initPdfSdk} from "../../initPdfViewer";
import {
  APP_INIT_STATUS,
  AUTH_CHECK_PURPOSE,
  FLOW_ORDERED, FLOW_STATUS, FOXIT_FIELD_TYPE,
  RECIPIENT_ITEM_STATUS,
  SEAL_TYPE,
  WARD_TYPE
} from "../../constants";
import {sessionCache} from "../../utils/storage";
import eventManager, {
  ALL_FIELD_HAS_FILLED,
  AUTH_CHECK_END,
  BATCH_SET_FIELD_VALUE,
  SHOW_AUTH_DIALOG
} from "@/utils/event";
import {getJwtToken} from "../../request/user";
import {weakTypeEqual} from "@/utils/common";

function loadPdf () {
  const docId = globalStore.serverPdfInfo?.doc_id;
  const key = `esignCn_Doc_${docId}`;
  return localforage.getItem(key).then((blob) => {
    if (blob) {
      return blob;
    }
    return downloadPdf({ docId }).then((data) => {
      return new Blob([data], {
        type: 'application/pdf;charset=utf-8',
      });
    })
  });
};

export async function loadFileAndInitSDK({ viewRef }) {
  updateInitLoadingStatus(APP_INIT_STATUS.SDK_LOADING)
  return loadPdf()
    .then((blob) => {
      return initPdfSdk({ viewer: viewRef, file: blob });
    })
    .then(() => {
      updateInitLoadingStatus(APP_INIT_STATUS.RESOLVE_LOADING)
    })
    .catch((e) => {
      console.error('pdf init error: ', e)
      updateInitLoadingStatus(APP_INIT_STATUS.DONE)
    })
}


export function _accountIsEqual(receiptItem, userEmail, userMobile) {
  const _emailIsEqual = (receiptItem, userEmail) => {
    return userEmail && receiptItem.email && receiptItem.email.toLowerCase() === userEmail.toLowerCase();
  }
  const _phoneIsEqual = (receiptItem, userMobile) => {
    return userMobile && receiptItem.phone && receiptItem.phone.slice(-11) === userMobile.slice(-11);
  }
  return _emailIsEqual(receiptItem, userEmail) || _phoneIsEqual(receiptItem, userMobile);
}

export function currentItemIsFreeRectItem(pdfReceipts) {
  const curItemList = getCurrentItem(pdfReceipts);
  return Array.isArray(curItemList) && curItemList.some(item => item.freeRectItem)
}

export function getSealTypesOfCurrentItems(serverPdfInfo) {
  // 获取当前可以签署的环节的签署类型： 对内签署 / 对外个人签署 / 对外企业签署，返回结果为数组类型；
  const wardType = serverPdfInfo.ward_type;
  const curItemList = getCurrentItemByServerPdfInfo(serverPdfInfo);
  if (wardType === WARD_TYPE.IN) {
    return [SEAL_TYPE.IN_PERSON]
  } else {
    if (curItemList.length === 1) {
      return curItemList[0].company ? [SEAL_TYPE.OUT_ORG] : [SEAL_TYPE.OUT_PERSON];
    } else {
      return [SEAL_TYPE.OUT_ORG, SEAL_TYPE.OUT_PERSON]
    }
  }
}

export function getCurrentItem(receipts) {
  const curOrderList = getCurrentItemOrder();
  if (Array.isArray(curOrderList) && curOrderList.length > 0) {
    let arr = [];
    curOrderList.forEach(order => {
      const item = receipts.find(it => weakTypeEqual(it.sort, order));
      if (item) {
        arr.push(item);
      }
    })
    return arr;
  } else {
    return null;
  }
}

export function getCurrentItemByServerPdfInfo(serverPdfInfo) {
  const recipients = serverPdfInfo.recipients;
  const curOrderList = getCurrentItemOrder();
  if (Array.isArray(curOrderList) && curOrderList.length > 0) {
    return recipients.filter(item => curOrderList.includes(parseInt(item.order)));
  } else {
    return null;
  }
}

export function getCurrentItemOrder() {
  // 该函数返回的是当前签署环节的order，由于存在个人企业同时签场景，因此返回的是一个数组

  const checkNextTurnItemOfCurUser = (curSignOrder, recipients) => {
    if (!curSignOrder) {
      return null;
    }
    let nextSignOrder = curSignOrder + 1;
    const nextItems = recipients.filter(item => weakTypeEqual(item['sign_order'], nextSignOrder));
    const nextCurUserItems = nextItems && nextItems.filter(item => _accountIsEqual(item, userEmail, userMobile) && item.status !== RECIPIENT_ITEM_STATUS.HAS_FINISHED);
    if (nextCurUserItems && nextCurUserItems.length > 0) {
      return nextCurUserItems.map(item => parseInt(item.order));
    } else {
      return null;
    }
  }

  let curOrder = null;
  const { userMobile, userEmail } = globalStore.userInfo;
  const serverPdfInfo = globalStore.serverPdfInfo;
  const cur_order = serverPdfInfo?.cur_order;
  const cur_sign_order = serverPdfInfo['cur_sign_order'];
  if (Array.isArray(cur_order) && cur_order.length > 0) {
    if (cur_order.length === 1) {
      curOrder = [parseInt(cur_order[0])];
    } else {
        let items = cur_order.map(order => {
          return serverPdfInfo.recipients[order];
        })
      items = items.filter(item => _accountIsEqual(item, userEmail, userMobile) && item.status !== RECIPIENT_ITEM_STATUS.HAS_FINISHED)
      if (!items || items.length === 0) {
        curOrder = [parseInt(cur_order[0])]
      } else {
        curOrder = items.map(it => parseInt(it.order));
      }
    }
  } else if (cur_order && typeof cur_order === 'string') { // 老版本的数据
    curOrder = [parseInt(cur_order)];
  } else {
    curOrder = null;
  }
  if (curOrder) {
    let existCurUserItem = serverPdfInfo.recipients.find(item => weakTypeEqual(item.order, curOrder[0]) && _accountIsEqual(item, userEmail, userMobile));
    if (existCurUserItem && getSignFlowOrderedType() !== FLOW_ORDERED.ORDERED) {
      const nextTurnCurUserOrders = checkNextTurnItemOfCurUser(cur_sign_order, serverPdfInfo.recipients);
      if (curOrder && nextTurnCurUserOrders) {
        curOrder = [...curOrder, ...nextTurnCurUserOrders]
      }
    }
  }
  return curOrder;
}

export function getSignFlowOrderedType() {
  const recipients = globalStore.serverPdfInfo.recipients;
  const signOrderList = new Set(recipients.map(item => item.sign_order)); // 通过Set来对signOrder去重
  if (signOrderList.size === recipients.length) { // 说明recipients里每个signOrder都不同，即为完全有序
    return FLOW_ORDERED.ORDERED;
  } else if ( signOrderList.size === 1) { // 说明recipients里每个signOrder都相同，即为完全无序
    return FLOW_ORDERED.UNORDERED;
  } else {
    return FLOW_ORDERED.PARTIAL;
  }
}

export function isCurrentUserTurnToSign(receipts) {
  const { userInfo } = globalStore;
  const { userMobile, userEmail } = userInfo;
  if (flowSignHasDone(receipts) || flowSignHasBroken()) {
    return false
  }
  const curItemList = getCurrentItem(receipts);
  return Array.isArray(curItemList) && curItemList.length > 0 && _accountIsEqual(curItemList[0], userEmail, userMobile);
}

export function flowSignHasDone (recipients) { // 判断流程签是否已经完成
  return recipients.every(item => {
    return Array.isArray(item.annots) && item.annots.length > 0 && item.annots.every(annot => annot.fieldStatus === 2);
  })
}

export function isInvalidContractFLow() {
  return globalStore.serverPdfInfo['invalid_process_id'] && globalStore.serverPdfInfo['invalid_process_id'] !== globalStore.serverPdfInfo['process_id'];
}

export function isInvalidOriginFlow() {
  return [FLOW_STATUS.INVALIDING.key, FLOW_STATUS.INVALID.key].includes(+globalStore.serverPdfInfo.status)
}

export function isInvalidFlowSign() {
  return isInvalidOriginFlow() || isInvalidContractFLow();
}

export function flowHasRefuse() {
  const refuseInfo = globalStore.serverPdfInfo && globalStore.serverPdfInfo.reject_info;
  return refuseInfo && refuseInfo.length > 0;
}

export function flowSignHasBroken() {
  const refuseInfo = globalStore.serverPdfInfo.reject_info;
  const flowHasRefused = refuseInfo && refuseInfo.length > 0;
  return globalStore.flowExpired || flowHasRefused
}

export function isLastUnsignedItem(serverPdfInfo, recipientOrder) {
  const unSignItems = serverPdfInfo.recipients.filter( item => item.status !== RECIPIENT_ITEM_STATUS.HAS_FINISHED);
  return unSignItems && unSignItems.length === 1 && parseInt(unSignItems[0].order) === parseInt(recipientOrder);
}

export function checkUserAuthPermission(receipts, sealType) {
  const noNeedCheckAuthFn = () => {
    let noNeedCheckAuth = false;
    if (flowSignHasDone(receipts) || flowSignHasBroken()) { // 该流程签已经完成
      noNeedCheckAuth = true;
    }
    if (!noNeedCheckAuth) {
      const ret = isCurrentUserTurnToSign(receipts) // 在初始化流程中，当前签署环节是否包含当前签署人 如果是，则需要走auth验证，否则不做，待用户点击签署按钮才进行auth验证
      if (!ret) {
        noNeedCheckAuth = true;
      }
    }
    return noNeedCheckAuth;
  }
  if (noNeedCheckAuthFn()) {
    eventManager.emit(AUTH_CHECK_END);
    sessionCache.removeAuthResult();
    return;
  }
  const { email, phoneNumber, company, companyId, userName } = getAuthCheckParamsFromPdfInfo(receipts);
  callAuthPluginChecking({ email, phoneNumber, company, companyId, userName, sealType}); // sealType优化到方法内部，以方便调用
}

export function getAuthCheckParamsFromPdfInfo(receipts) {
  const itemList = getCurrentItem(receipts);
  let item = Array.isArray(itemList) && itemList.find(item => item.company);
  if (!item) {
    item = itemList[0];
  }
  const params = {
    email: item.email,
    phoneNumber: item.phoneNumber,
    company: item.company,
    companyId: item.EnterpriseId,
    userName: item.userName,
  }
  return params;
}

export function callAuthPluginChecking({email, phoneNumber, company, companyId, userName, sealType, purpose = AUTH_CHECK_PURPOSE.FLOW_CHECK, rdl= `${window.location.origin}/pages/auth-result/auth-result` }) {
  const getAuthCheckResultByCache = sessionCache.getAuthResult();
  if (getAuthCheckResultByCache) {
    if (getAuthCheckResultByCache.success) {
      return;
    }
  }
  const params = {
    show: true,
    email,
    phoneNumber,
    company,
    companyId,
    userName,
    sealType,
    purpose,
    rdl
  }
  eventManager.emit(SHOW_AUTH_DIALOG, params); // email, phoneNumber, company, companyId, userName
}

export function getFlowSignSealType(receipt, serverPdfInfo) {
  let sealType;
  const receiptItemList = getCurrentItem(receipt);
  if (!Array.isArray(receiptItemList)) {
    return;
  }
  let receiptItem = receiptItemList.find(item => item.company);
  if (!receiptItem) {
    receiptItem = receiptItemList[0];
  }
  if (serverPdfInfo.ward_type === WARD_TYPE.IN) {
    sealType = SEAL_TYPE.IN_PERSON
  }
  if (serverPdfInfo.ward_type === WARD_TYPE.OUT) {
    if (receiptItem && receiptItem.company) {
      sealType = SEAL_TYPE.OUT_ORG;
    } else {
      sealType = SEAL_TYPE.OUT_PERSON;
    }
  }
  return sealType;
}

export function getFoxitFieldTypeLabel(foxitSignFieldType, isPagingSig = false) {
  let name;
  switch (foxitSignFieldType) {
    case FOXIT_FIELD_TYPE.NORMAL_SIG:
      name = "签名域";
      break;
    case FOXIT_FIELD_TYPE.SIGN_DATE:
      name = "日期签名域";
      break;
    case FOXIT_FIELD_TYPE.FREE_RECT:
      name = "签名域";
      break;
    case FOXIT_FIELD_TYPE.DATE:
      name = "日期域";
      break;
    case FOXIT_FIELD_TYPE.TITLE:
      name = "文本域";
      break;
    default:
      name = "";
      break;
  }
  if (isPagingSig) {
    return "骑缝章"
  }
  return name;
}

export const checkInerAuth = async (enterpriseId, onlyCheck = false) => {
  const saleText = '联系销售';
  const link = 'https://www.foxitsoftware.cn/esign#sales';

  // 判断是否为企业帐户
  if (!enterpriseId) {
    return alert(-1, '个人帐户不支持此功能，请切换企业帐户登录，若您尚未开通企业帐户，请联系销售为您创建企业信息。', saleText, link);
  }

  // 判断企业是否订阅或用户是否有授权
  let res = await checkAuthApi();
  if (!res || res <= 0) {
    if (res === -1) {
      return alert(-2, '对内签署服务未订阅或订阅过期，请联系销售为您所在的企业订阅该服务。', saleText, link);
    }
    if (res === -2) {
      return alert(-3, '您尚未被分配对内签署授权，请联系当前企业IT管理员为您分配授权。');
    }
    return alert(-4, '查询企业订阅/授权信息失败');
  }

  if (onlyCheck) {
    return result(1);
  }

  // 判断是否有saml
  let samlResponse, jwtToken;
  res = await getSAMLResponse();
  if (!res.data || !res.data.samlAssertion) {
    // 获取jwtToken
    res = await getJwtToken();
    if (res) {
      jwtToken = res;
    } else {
      return result(-5, '获取JWT失败');
    }
  } else {
    samlResponse = res;
    // 判断归属
    const enterpriseUserRelation = await checkEnterpriseUser();
    if (enterpriseUserRelation !== true) {
      return alert(-6, '当前登录帐户未归属当前企业，请更换帐户重新登录');
    }
  }

  // 判断订阅信息
  res = await getSubscription({wardType: 1});
  if (!res || !res.serverTime) {
    return alert(-7, '获取企业订阅信息失败');
  }
  const userSubscription = res;
  if (!userSubscription.serverTime || !userSubscription.expreTime || userSubscription.serverTime >= userSubscription.expreTime) {
    return alert(-8, '对内签署服务已过期，请联系销售为您所在的企业续订该服务。', saleText, link);
  }

  return result(1, samlResponse, userSubscription, jwtToken);

  function result(state = 0, samlResponse = '', userSubscription = null, jwtToken = '', alert = null) {
    return {
      state,
      samlResponse,
      jwtToken,
      userSubscription,
      alert
    };
  }

  function alert(state, content = '数据查询错误，请稍后重试', okText = '确认', okLink = undefined) {
    const alert = {
      content,
      okText,
      okLink,
    }
    return result(state, undefined, undefined, undefined, alert);
  }
};

export function getActiveSealInfo(key, sealType) {
  let sealInfo = null;
  const sealsInfoFromCache = localStorage.getItem('activeSealInfo');
  if (sealsInfoFromCache) {
    const sealListInfo = JSON.parse(sealsInfoFromCache);
    sealInfo = sealListInfo[key] || null;
  }
  if (sealInfo) {
    if (sealType === SEAL_TYPE.OUT_PERSON) {
      sealInfo.sealType = SEAL_TYPE.OUT_PERSON_VALUE;
    } else if (sealType === SEAL_TYPE.OUT_ORG) {
      sealInfo.sealType = SEAL_TYPE.OUT_ORG_VALUE;
    } else {
      sealInfo.sealType = SEAL_TYPE.IN_PERSON_VALUE;
    }
  }
  return sealInfo;
}

export function setActiveSealInfo(key, sealInfo) {
  let sealsInfo = {};
  const sealsInfoFromCache = localStorage.getItem('activeSealInfo');
  if (sealsInfoFromCache) {
    sealsInfo = JSON.parse(sealsInfoFromCache);
  }
  sealsInfo[key] = sealInfo;
  localStorage.setItem('activeSealInfo', JSON.stringify(sealsInfo)); // 需要考虑对外刷新页面后，签章信息丢失的问题，所以需要存储到localStorage中
}

export const generateSealKey = (serverPdfId, pageIndex, annotObjNum) => `sealInfo_${serverPdfId}_${pageIndex}_${annotObjNum}`

export function cleanActiveSealInfo() {
  localStorage.removeItem('activeSealInfo');
}

export function getCurrentDocBlob() {
  const workerFetch = window.pdfDocRender.pdfViewer.workerFetch;
  const docId = pdfDocRender.docId

  const buffers = [];
  const progress = workerFetch.register(function (buffer) {
    // 文档buffer数据在回调函数中返回
    buffers.push(buffer);
  });
  return workerFetch.request('doc/download', {
    doc: docId,
    download: { progress, flag: 1 },
  }).then(function () {
    return new Blob(buffers);
  });
}

export async function setDocCache(doc_id) {
  const blob = await getCurrentDocBlob();
  const serverDocId = doc_id;
  const key = `esignCn_Doc_${serverDocId}`;
  return localforage.setItem(key, blob)
}

export function flowSignHasExpired(serverFlowInfo) {
  if (!serverFlowInfo) return false;
  return FLOW_STATUS.EXPIRED.key.includes(+serverFlowInfo.status);
}

export function getWardTypeLabel() {
  return globalStore.serverPdfInfo.ward_type === WARD_TYPE.IN ? "对内签署" : "对外签署"
}

export function getFreeRectTypeLabel() {
  return globalStore.serverPdfInfo.recipients[1]?.free_rect_item === "1" ? "不指定签署区域" : "指定签署区域"
}

export function getFlowOrderTypeLabel() {
  return getSignFlowOrderedType() === FLOW_ORDERED.ORDERED ? "按顺序签署" : "无顺序签署"
}


export function handleFillAllField(fields) {
  const unFilledFields = fields.filter( field => !field.tempState.hasSetToPdf);
  if(unFilledFields && unFilledFields.length > 0) {
    eventManager.emit(BATCH_SET_FIELD_VALUE, unFilledFields);
  } else {
    eventManager.emit(ALL_FIELD_HAS_FILLED);
  }
}

export function isRefuseFlow() {
  const refuseInfo = globalStore.serverPdfInfo.reject_info;
  const flowHasRefused = refuseInfo && refuseInfo.length > 0;
  return flowHasRefused;
}
