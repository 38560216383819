import qs from "qs";
import {getServerConfig, getTokenByTicket, getUserInfo, logout} from "./request/user";
import {getProcessCCInfo, getSignFlowInfo, getTenantName} from "./request/esign";
import globalStore, {
  updateConfigUrls,
  updateInitLoadingStatus,
  updateServerPdfInfo,
  updateUserInfo,
  updateUserStatus
} from "./store";
import {
  APP_INIT_STATUS,
  DEVAZK8S_HOST_KEY,
  ERROR_CODE,
  FLOW_STATUS,
  NO_FLOW_PAGES,
  USER_STATUS,
  VCONSOLE_URL
} from "./constants";
import {sessionCache} from "./utils/storage";
import {redirectTo} from "@tarojs/taro";
import otelTool from '@/utils/otel';
import {fixMobileBrowserBottomToolBarHeight} from "./utils/device";
import {setQueryCache} from "./pages/index/service";
import {loadScript} from "@/utils/common";


function getParams() {
  const search = window.location.search;
  const index = search.indexOf('?');
  let params = {};
  if (index >= 0) {
    params = search.slice(index + 1);
    params = qs.parse(params);
  }
  return params;
}
// 获取domains
export function getUrlConfig() {
  const configUrls = JSON.parse(localStorage.getItem('configUrls') || '{}');
  if (configUrls.accountApiUrl) {
    updateConfigUrls(configUrls)
    return Promise.resolve(configUrls);
  }
  return getServerConfig().then((urls) => {
    updateConfigUrls(configUrls)
    localStorage.setItem('configUrls', JSON.stringify(urls));
    return urls;
  })
}

function setToken() {
  const data = {
    token: sessionCache.getToken() ?? '',
    enterpriseId: sessionCache.getEnterpriseId() ?? '',
    enterpriseName: sessionCache.getEnterpriseName() ?? '',
  };
  let task = Promise.resolve();
  if (data.token && data.enterpriseId && !data.enterpriseName) {
    task = getTenantName({token: data.token, tenantId: data.enterpriseId});
  }
  return task.then(res => {
    if(res && res.tenantName) {
      data.enterpriseName = res.tenantName;
      sessionCache.setEnterpriseName(data.enterpriseName)
    }
    const search = window.location.search;
    const index = search.indexOf('?');
    if (index >= 0) {
      let params = search.slice(index + 1);
      params = qs.parse(params);
      const {ticket, enterpriseId, id} = params;
      if (ticket) {
        return getTokenByTicket(ticket).then(token => {
          sessionCache.setEnterpriseId(enterpriseId || '')
          sessionCache.setToken(token);
          if (id) {
            sessionCache.setProcessId(id);
          }

          data.token = token;
          data.enterpriseId = enterpriseId;
          window.history.pushState({}, '', '/');
          return data;
        })
      } else {
        return Promise.resolve(data);
      }
    } else {
      return Promise.resolve(data);
    }
  })
}

export function getEnterpriseId() {
  return sessionCache.getEnterpriseId() ?? '';
}

export function getToken() {
  return sessionCache.getToken() ?? '';
}

function _getPdfInfo() {
  let processId = sessionCache.getProcessId();
  const search = window.location.search;
  const index = search.indexOf('?');
  if (index >= 0) {
    let params = search.slice(index + 1);
    params = qs.parse(params);
    const {id, supplierId} = params;
    if (id) {
      processId = id;
      sessionCache.setProcessId(processId);
      sessionCache.setSupplierId(supplierId||'1')
      window.history.pushState({}, '', '/');
    }
  }

  if (!processId || processId === 'undefined') {
    return Promise.reject(ERROR_CODE.NOT_EXIST_FLOW_INFO);
  }
  let task = Promise.resolve();
  if(getToken()) {
    task = getProcessCCInfo({ processId })
  }
  return task.then(ccInfo => {
    const cc = ccInfo ? checkAccountFromCCList(ccInfo) : false;
    return getSignFlowInfo({ processId, cc }).then(res => {
      if (res.data && Object.keys(res.data).length === 0) {
        return Promise.reject(ERROR_CODE.NOT_EXIST_FLOW_INFO);
      }

      const {simplified_process, process} = res;
      let info;
      if (process) {
        const pass =  checkFlowExceptionStatus(process);
        if (!pass) return ;
        info = process;
        info.ccInfo = ccInfo;
        if (cc) {
         const ccCheckDetail = ccUserCheck(process)
         if (!ccCheckDetail.isValidCcUser) { // 不是合法的抄送人，即 登录的企业不对
           return getSignFlowInfo({ processId }).then(res => { // process/info接口对于抄送校验逻辑不合理导致此处重复查询
             const {simplified_process, process} = res;
             if (process) {
               info = process;
               info.ccInfo = ccInfo;
             } else if (simplified_process) {
               info = simplified_process;
             } else {
               info = {}
             }
             updateServerPdfInfo(info);
             return info;
           })
         }
        }
      } else if (simplified_process) {
        info = simplified_process;
        const pass =  checkFlowExceptionStatus(simplified_process);
        if (!pass) return ;
      } else {
        info = {};
      }
      updateServerPdfInfo(info)
      return info;
    })
  })
}

function _getUserInfo(enterpriseId, enterpriseName) {
  return getUserInfo().then(info => {
    updateUserInfo({ ...info, enterpriseId, enterpriseName })
    return true;
  })
}

function initOtelTool () {
  if (globalStore.configUrls || !globalStore.configUrls.otelTraceUrl) {
    otelTool.init(globalStore.configUrls.otelTraceUrl, 'cloud-ods-collector', '2.0.0');
  }
}

function vconsoleTool() {
  if(window.location.origin.indexOf(DEVAZK8S_HOST_KEY) > -1 && window.location.origin.split('.').length > 3) {
    loadScript(VCONSOLE_URL).then(
      () => {
        console.log(window.navigator.userAgent);
        console.log('window.VConsole', window.VConsole);
        new window.VConsole();
      },
    );
  }
}

function initSystemTool() {
  initOtelTool();
  fixMobileBrowserBottomToolBarHeight();
  setQueryCache();
  vconsoleTool();
  return Promise.resolve();
}

export function initApp() {
  updateInitLoadingStatus(APP_INIT_STATUS.SERVER_LOADING)
  return getUrlConfig()
  .then(() => {
    return initSystemTool()
  })
  .then(() => {
      if(NO_FLOW_PAGES.includes(window.location.pathname)) {
        return Promise.reject();
      } else {
        return Promise.resolve();
      }
    })
  .then(() => {
    return setToken();
  })
  .then(data => {
    let getUserInfoTask = Promise.resolve();
    if (data.token) {
      getUserInfoTask = _getUserInfo(data.enterpriseId, data.enterpriseName);
    } else {
      updateUserStatus(USER_STATUS.NO_LOGIN);
    }
    return getUserInfoTask;
  })
  .then(() => {
      return _getPdfInfo();
    })
  .then(info => {
    if (info && !info.creator_name.includes('*')) { // 参与者或发起者，可查看。这里去除了 对token有无的判断，因为无token时，creator_name肯定是脱敏的
      updateUserStatus(USER_STATUS.HAS_LOGIN)
    } else {
      updateUserStatus(USER_STATUS.NO_READ_PERMISSION);
    }
    return info;
  })
  .then(() => {
    if (window.location.pathname !== '/') {
      updateInitLoadingStatus(APP_INIT_STATUS.DONE)
    }
  })
  .catch(e => {
      updateInitLoadingStatus(APP_INIT_STATUS.DONE)
      if (e === ERROR_CODE.NOT_EXIST_FLOW_INFO) {
        updateInitLoadingStatus(APP_INIT_STATUS.NOT_EXIST_FLOW_INFO);
      }
      console.log(e)
  })
}

export function logoutAction(redirectUrl) {
  const token = getToken();
  return logout(token, redirectUrl);
}

export function getSupplierId(isOuter = true) {
  const querys = getParams() || {}
  let supplierId = parseInt(querys.supplierId) || localStorage.getItem('supplierId');
  const outer =  supplierId > 0 ? supplierId : 1; // 1易签宝，2沃通 3敏行对外 4敏行对内
  const inner = outer > 1 ? 4 : 2;
  if (isOuter) return outer;
  return inner;
}


export function ccUserCheck(serverPdfInfo) { // 当用户不在签署列表中时，再判断是否是抄送用户，如果用户即是抄送用户又是签署用户，则以签署用户为准，不做抄送用户的相关逻辑处理
  let isCcUser = false;
  let isValidCcUser = false;
  const { ward_type, recipients, ccInfo } = serverPdfInfo;
  const { userMobile, userEmail, enterpriseId, enterpriseName } = globalStore.userInfo;
  const initorEnterpriseId = recipients[0].company.split('-')[0];
  const emailIsEqual = (receiptItem) => {
    return userEmail && receiptItem.email && receiptItem.email.toLowerCase() === userEmail.toLowerCase();
  }
  const phoneIsEqual = (receiptItem) => {
    return userMobile && receiptItem.phone && receiptItem.phone.slice(-11) === userMobile.slice(-11);
  }
  const accountIsEqual = (receiptItem) => {
    return emailIsEqual(receiptItem) || phoneIsEqual(receiptItem);
  }
  const ret = recipients.every(item => {
    return !accountIsEqual(item);
  })
  if (ret) { // 不是签署用户
    let ccUsers = ccInfo.filter(item => {
      return accountIsEqual(item);
    })
    if (ccUsers && ccUsers.length > 0) { // 当前用户是抄送用户
      isCcUser = true;
      if (ward_type === '1') { // 对内 需要判断 抄送人登录的企业ID
        isValidCcUser = enterpriseId === initorEnterpriseId;
      } else { // 对外 需要考虑 抄送给该用户的是公司还是个人
        let companyCc = ccUsers.find(item => item.company);
        if (companyCc) { // 如果是抄送公司，需要判断抄送公司是否是当前用户所在的公司
          isValidCcUser = enterpriseName === companyCc.company;
        } else {
          isValidCcUser = true;
        }
      }
    } else { // 当前用户不是抄送用户
      isCcUser = false;
    }
  } else { // 是参与签署用户
    isCcUser = false;
  }
  return {
    isCcUser,
    isValidCcUser
  }
}

export function checkAccountFromCCList(ccList) {
  const { userMobile, userEmail } = globalStore.userInfo;
  const emailIsEqual = (receiptItem) => {
    return userEmail && receiptItem.email && receiptItem.email.toLowerCase() === userEmail.toLowerCase();
  }
  const phoneIsEqual = (receiptItem) => {
    return userMobile && receiptItem.phone && receiptItem.phone.slice(-11) === userMobile.slice(-11);
  }
  const accountIsEqual = (receiptItem) => {
    return emailIsEqual(receiptItem) || phoneIsEqual(receiptItem);
  }
  if(ccList && ccList.length > 0) {
    return ccList.some(item => accountIsEqual(item));
  } else {
    return false;
  }
}

export function getRefuseInfo(serverPdfInfo) {
  const refuseInfo = serverPdfInfo.reject_info;
  if (typeof refuseInfo === 'object') {
    return refuseInfo;
  }
  if (!refuseInfo || refuseInfo.length < 1) {
    return null;
  } else {
    const data = JSON.parse(refuseInfo);
    data.recipientOrder = parseInt(data.recipient_order, 10);
    delete data.recipient_order;
    return data;
  }
}

export function checkFlowExceptionStatus(serverPdfInfo) {
  let pass = true;
  const status = parseInt(serverPdfInfo.status, 10);
  serverPdfInfo.cancel_info = serverPdfInfo.cancel_info ? JSON.parse(serverPdfInfo.cancel_info) : {}
  serverPdfInfo.cancel_info.name = serverPdfInfo.name;
  serverPdfInfo.cancel_info.creator = serverPdfInfo.creator_name;
  serverPdfInfo.cancel_info.company = serverPdfInfo.recipients[0].company.split('-')[1] ? serverPdfInfo.recipients[0].company.split('-')[1] : '';
  serverPdfInfo.cancel_info = JSON.stringify(serverPdfInfo.cancel_info);
  if (FLOW_STATUS.CANCELED.key.includes(status)) {
    redirectTo({
      url: `pages/flow-revoke/index?s=${status}&desc=${encodeURIComponent(serverPdfInfo.cancel_info)}`,
    });
    pass = false;
  }
  return pass;
}

export function handleFLowDeleteStatus() {
  redirectTo({
    url: `pages/flow-revoke/index?del=1`,
  });
}

export function resolveInnerSealSize(info) {
  const size = { width: 0, height: 0, scale: 0 };
  if (info.width > 0 && info.height > 0) { // 对内该字段的值的单位为mm
    size.width = Math.ceil(info.width * 96 / 25.4);
    size.height = Math.ceil(info.height * 96 / 25.4);
  } else {
    if (info.scale > 0) {
      //  暂无该场景
    } else if(info.picture_width > 0 && !info.picture_height)  {
      size.scale = info.picture_width;
    } else if (info.picture_height > 0 && !info.picture_width) {
      size.scale = info.picture_height;
    } else {
      size.width = info.picture_width;
      size.height = info.picture_height;
    }
  }
  return size;
}
