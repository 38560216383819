import qs from "qs";
import cuid from 'cuid';
import {getElementDomRect, reverseDeviceRect} from "./deviceRectReverse";
import {sessionCache} from "@/utils/storage";
import globalStore from "@/store/index";

export function getParams() {
  const search = window.location.search;
  const index = search.indexOf('?');
  let params = {};
  if (index >= 0) {
    params = search.slice(index + 1);
    params = qs.parse(params);
  }
  return params;
}

export function getBase64ImageDimensions(base64Data) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = function() {
      resolve({
        width: this.width,
        height: this.height
      });
    };
    image.onerror = function() {
      reject(new Error('Failed to load image'));
    };
    image.src = base64Data;
  });
}

export function getStampDeviceSize({ width, height, scale }) {
  const deviceWidth = width * scale;
  const deviceHeight = height * scale;
  return { width: deviceWidth, height: deviceHeight }
}

export function generateCUID() {
  return cuid();
  // function getRandomLetter() {
  //   const alphabet = 'abcdefghijklmnopqrstuvwxyz';
  //   return alphabet.charAt(Math.floor(Math.random() * alphabet.length));
  // }
  // let timestamp = Date.now().toString(36).padStart(10, '0');
  // let random = Math.random().toString(36).substr(2, 14).padEnd(14, '0');
  // // 将第一位数字转换为字母
  // timestamp = timestamp.replace(/^\d/, getRandomLetter());
  // return `${timestamp}${random}`;
}

export function base64ToArrayBufferUtil(base64) {
  base64 = base64.replace(/^data:.*?base64,/, '');
  let bstr = window.atob(base64),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return u8arr;
};

export function getRectByClassNme({className, rotation, scale, pdfHeight, pdfWidth} ) {
  const targetEle = document.querySelector(`.${className}`);
  const pageEle = targetEle.parentNode;
  const domRect = getElementDomRect(pageEle, targetEle); // miniSDK本身有支持
  return reverseDeviceRect({ domRect, scale, rotate: rotation, pdfHeight, pdfWidth })
}

export function formatDate(type, defaultDate) {
  const date = defaultDate ? new Date(defaultDate) : new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const monthStr = month < 10 ? `0${month}` : month;
  const day = date.getDate();
  const daystr = day < 10 ? `0${day}` : day;
  const hour24 = date.getHours();
  const hourstr = hour24 < 10 ? `0${hour24}` : hour24;
  const minute = date.getMinutes();
  const minutestr = minute < 10 ? `0${minute}` : minute;
  const second = date.getSeconds();
  const secondstr = second < 10 ? `0${second}` : second;

  switch (type) {
    case 1:
      return `${year}/${monthStr}/${daystr}`;
    case 2:
      return `${year}-${monthStr}-${daystr}`;
    case 3:
      return `${year}年${monthStr}月${daystr}日`;
    case 4:
      return `${year}-${monthStr}-${daystr} ${hourstr}:${minutestr}:${secondstr}`;
  }
}

export function getEleAffiliatedLocation(primaryEle, affiliatedEleSize) {
  const primaryRect = primaryEle.getBoundingClientRect();
  const containerRect = primaryEle.parentNode.getBoundingClientRect();
  let top = primaryRect.top - containerRect.top + primaryRect.height + 20
  if (top > containerRect.bottom - containerRect.top) {
    top = primaryRect.top - containerRect.top - affiliatedEleSize.height  - 10;
  }
  let left = primaryRect.left - containerRect.left;
  if (left + affiliatedEleSize.width > containerRect.right - containerRect.left) {
    left = primaryRect.right - containerRect.left - affiliatedEleSize.width;
  }
  return { top, left }
}

export function drawTextToCanvas ({text, fontType = 'Times New Roman,SimHei',
                                    fontSize = 30, color = '#000', width = 365, height = 100}) {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext("2d");
  ctx.globalAlpha = 1;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillStyle = color;
  ctx.font=`${fontSize}px  ${fontType}`;
  return new Promise((resolve) => {
    setTimeout(() => {
      let metrics = ctx.measureText(text);
      let len = Math.ceil(metrics.actualBoundingBoxLeft + metrics.actualBoundingBoxRight);
      while (len > width) {
        fontSize -= 2;
        ctx.font=`${fontSize}px  ${fontType}`;
        metrics = ctx.measureText(text);
        len = Math.ceil(metrics.actualBoundingBoxLeft + metrics.actualBoundingBoxRight);
      }
      ctx.fillText(text, width/2, height/2);
      resolve(canvas.toDataURL('image/png'));
    }, 100); // 因特殊字体的加载需要时间，这儿做一个延时处理
  });
}

export function getQueryVariable(variable) {
  let query = window.location.search.substring(1);
  let vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    if (pair[0] === variable) {
      let value = decodeURIComponent(pair[1]);
      return value;
    }
  }
  return null;
}

export function removeQueryString() {
  const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
  history.replaceState({}, document.title, newUrl);
}

export function loadScript(url, params = {} /* callback */) {
  return new Promise((resolve, reject) => {
    let head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.type = 'text/javascript';
    if (Object.keys(params).length) {
      for (const key in params) {
        if (Object.hasOwnProperty.call(params, key)) {
          script[key] = params[key];
        }
      }
    }

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          resolve();
        }
      };
    } else {
      // script.οnlοad=function(){
      //   resolve();
      // }
      script.onload = resolve;
      script.onerror = reject;
    }
    script.src = url;
    head.appendChild(script);
  });
}

export function weakTypeEqual(a, b) {
  return a + '' === b + '';
}

export function truncateDomain(origin) {
  origin = removeLastSlash(origin);
  const domains = origin.split('.');
  if (domains.length > 3) {
    return 'https://' + domains.slice(-3).join('.');
  } else {
    return origin;
  }
}


export function securityCheckPcDomain(origin) {
  if(origin.indexOf('localhost')) return origin;
  const truncateOrigin = truncateDomain(origin);
  let newEsignCNpc = globalStore.configUrls?.['newEsignCNpc'];
  newEsignCNpc = removeLastSlash(newEsignCNpc);
  if (truncateOrigin !== newEsignCNpc) {
    throw new Error(`invalid origin: ${origin}`)
  } else {
    return origin;
  }
}

function removeLastSlash(str) {
  if (str.endsWith('/')) {
    str = str.slice(0, -1); // 移除最后一个字符
  }
  return str;
}

export function getEleTranslateValue(element) {
  let translateX = 0, translateY = 0;
  const style = window.getComputedStyle(element);
  const transform = style.transform || style.webkitTransform || style.mozTransform;
  const matches = transform.match(/-?\d+\.?\d*/g);
  if (matches) {
    translateX = parseInt(matches[4]);
    translateY = parseInt(matches[5]);
  }
  return {
    translateX,
    translateY
  }
}
