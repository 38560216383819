import eventManager, {
  AUTH_SUCCESS,
  PDF_FIELD_DISABLED,
  CLICK_FIELD_ITEM,
} from "@/utils/event";
import {SEAL_TYPE} from "../../constants";
import otelTool from "@/utils/otel";
import {getQueryVariable} from "../../utils/common";
import {sessionCache} from "../../utils/storage";
import {handleFieldClick} from "../../plugins/utils";


export function setQueryCache() {
  const cf = getQueryVariable('cf');
  if (cf) {
    sessionCache.setCF(cf);
  }
}

class EventCenter {
  constructor(eventManager) {
    this.eventManager = eventManager;
  }
  emitFormPluginDisabledEvent() {
    const params = {
      disabled: false,
    }
    this.eventManager.emit(PDF_FIELD_DISABLED, params);
    sessionStorage.setItem('PDF_FIELD_DISABLED_DATA', JSON.stringify(params));
    otelTool.startCommonSpan(`handle-flow`).then(res => res.span.end());
  }
  emitGoToFieldEvent(data) {
    // this.eventManager.emit(ACTIVE_FIELD, data);
    const pdfDocRender = window.pdfDocRender
    handleFieldClick({ eSignAnnotKey: data.eSignAnnotKey, pdfDocRender, pageIndex: data.pageIndex })
  }

  init() {
    this.eventManager.on(AUTH_SUCCESS, this.emitFormPluginDisabledEvent.bind(this))
    this.eventManager.on(CLICK_FIELD_ITEM, this.emitGoToFieldEvent.bind(this))
  }
  destroy() {
    this.eventManager.off(AUTH_SUCCESS, this.emitFormPluginDisabledEvent.bind(this))
    this.eventManager.off(CLICK_FIELD_ITEM, this.emitGoToFieldEvent.bind(this))
  }
}

export default new EventCenter(eventManager)
