import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-proto';
import { WebTracerProvider, BatchSpanProcessor } from '@opentelemetry/sdk-trace-web';
import { Resource } from '@opentelemetry/resources';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import {context, propagation}  from "@opentelemetry/api";

const spanPrefix = ''

class OtelTool {
  constructor() {
    this.currentActiveSpan = null
    this.currentActiveContext = null;
    this.globalTracer = null;
    this.context = null;
    this.otelInitialized = false;
  }
  init(serverUrl, name, version) {
    if(this.otelInitialized || !serverUrl) return;
    const provider = new WebTracerProvider({
      resource: new Resource({
        [SemanticResourceAttributes.SERVICE_NAME]: 'esigncn_mobile'
      })
    })
    const collectorOptions = {
      url: `${serverUrl}/v1/traces`, // url is optional and can be omitted - default is http://localhost:4318/v1/traces
      headers: {}, // an optional object containing custom headers to be sent with each request
      concurrencyLimit: 10, // an optional limit on pending requests
    };
    const exporter = new OTLPTraceExporter(collectorOptions);

    provider.addSpanProcessor(new BatchSpanProcessor(exporter, {
      maxQueueSize: 100,
      maxExportBatchSize: 10,
      scheduledDelayMillis: 500,
      exportTimeoutMillis: 30000,
    }));
    provider.register();
    const tracer = provider.getTracer(
      name, version
    );
    this.context = context;
    this.globalTracer = tracer;
    this.otelInitialized = true;
    this.propagation = propagation;
  }

  async startCommonSpan(spanName, callback, error) {
    const currentActiveSpan = this.currentActiveSpan;
    if (currentActiveSpan) {
      return this.startChildSpan(`${spanPrefix}-${spanName}`);
    }
    return await this.startActiveSpan(`${spanPrefix}-${spanName}`, callback, error);
  }

  startChildSpan(spanName, options) {
    if (spanName) {
      const activeContext = this.currentActiveContext;
      const childSpan = this.startSpan(spanName, options, activeContext);
      return {
        span: childSpan,
      };
    } else {
      return {
        span: null,
        end: () => {},
      };
    }
  }

  startActiveSpan(spanName) {
    return new Promise((resolve, reject) => {
      if(!this.context || !this.globalTracer) {
        reject();
      }
      this.globalTracer.startActiveSpan(spanName, (span) => {
        this.generateActiveContext(spanName)
        span.setAttribute("foxit.category", "otel_traces");
        this.currentActiveSpan = span
        resolve({span});
      })
    })
  }
  startSpan(spanName, options = {}, context) {
    if(!this.context || !context) return;
    let span = this.globalTracer.startSpan(spanName, options, context);
    span.setAttribute("foxit.category", "otel_traces");
    return span;
  }
  getTraceHeaders() {
    let activeContext = this.currentActiveContext;
    if(!this.context) return {}
    let traceHeaders = {};
    activeContext = activeContext || this.context.active();
    this.propagation.inject(activeContext, traceHeaders)
    return traceHeaders;
  }
  //
  // setSpanException(span, exception, attributes) {
  //   span&&span.setStatus({ code: 'ERROR', message: exception });
  //   span&&span.recordException(exception, attributes)
  // }
  generateActiveContext() {
    if(!this.context) return;
    const activeContext = this.context.active()
    this.currentActiveContext = activeContext
    return this.currentActiveContext
  }
}

export default new OtelTool();
