import BaseComponent from './BaseComponent';
import './index.less';
import eventManager, {
  NORMAL_SIG_FIELD_CREATE_SIGN_DATE_FIELD,
  PDF_FIELD_DISABLED,
  SELECT_SEAL_STAMP,
  SHOW_SEAL_ACTION_SHEET
} from "@/utils/event";
import {FOXIT_FIELD_TYPE, SEAL_TYPE, WARD_TYPE} from "../../constants";
import fieldsData from '@/components/main/flowData';
import {sessionCache} from "@/utils/storage";


export default class SignComponent extends BaseComponent {
  createDom() {
    super.createDom();
    const labelId = `eSignCN-label-${this.widget.eSignAnnotKey}`;// eSignAnnotKey 挪到 eSignOption
    const eLabel = this.eLabel;
    eLabel.id = labelId;
    this.hasSetAp = false;
    this.sealActionSheet = null;
    const eDom = (this.eDom = document.createElement('div'));
    eDom.classList.add('sign-drapbox');

    this.hideDom();
    eLabel.appendChild(eDom);
    // this.sealActionSheet = new SealActionSheet({ handDraw: true, selectSealFn: this.selectSealFn.bind(this) })
    this._createToolBar(this.eLabel)
    this.initSetAp();
  }

  _createToolBar(eLabel) {
    function _updateToolBarPosition(toolBarEle, type, signEle, pagePadEleRect) {
      const signEleRect = signEle.getBoundingClientRect();
      const docEleRect =  window.pdfDocRender.eDoc.getBoundingClientRect();
      const toolBarEleRect = toolBarEle.getBoundingClientRect();

      if (type === 'bottom') {
        const styleList = {
          top: `${signEle.offsetTop + signEleRect.height + 20}px`,
          left: `${signEle.offsetLeft}px`,
          bottom: 'initial',
          right: 'initial',
        }
        Object.keys(styleList).forEach(key => {
          toolBarEle.style[key] = styleList[key];
        })
        toolBarEle.setAttribute('data-position', 'bottom');
      }
      if (type === 'top') {
        const styleList = {
          top: `${signEle.offsetTop + pagePadEleRect.top - docEleRect.top - 50}px`,
          left: `${signEle.offsetLeft}px`,
          bottom: 'initial',
          right: 'initial',
        }
        Object.keys(styleList).forEach(key => {
          toolBarEle.style[key] = styleList[key];
        })
        toolBarEle.setAttribute('data-position', 'top');
      }
      if (type === 'left') {
        const styleList = {
          top: `${signEle.offsetTop + pagePadEleRect.top - docEleRect.top + (signEleRect.height - toolBarEleRect.height) / 2}px`,
          left: `${signEle.offsetLeft - 160}px`,
          bottom: 'initial',
          right: 'initial',
        }
        Object.keys(styleList).forEach(key => {
          toolBarEle.style[key] = styleList[key];
        })
        toolBarEle.setAttribute('data-position', 'left');
      }
    }
    const toolBar = document.createElement('div');
    toolBar.setAttribute('data-position', 'top')
    toolBar.setAttribute('data-key', this.widget.eSignAnnotKey);
    toolBar.classList.add('free-rect-stamp__toolbar');
    const signEleRect = eLabel.getBoundingClientRect();

    const styleList = {
      top: `${signEleRect.top - 50}px`,
      left: `${signEleRect.left}px`,
      bottom: 'initial',
      right: 'initial',
      visibility: 'hidden',
    }
    Object.keys(styleList).forEach(key => {
      toolBar.style[key] = styleList[key];
    })

    const span1 = document.createElement('span');
    const span2 = document.createElement('span');
    span1.style.display = 'inline-block'
    span1.style.width = '50%'
    span1.style.textAlign = 'center'
    span2.style.display = 'inline-block'
    span2.style.width = '50%'
    span2.style.textAlign = 'center'
    span1.innerText = "换章";
    span2.innerText = "设置日期";
    span1.onclick = () => {
      this.setAp();
    }
    span2.onclick = () => {
      const signDateStampKey = `date-${this.widget.eSignAnnotKey}`;
      const wardType = this.getWardType();
      let sealType = SEAL_TYPE.IN_PERSON;
      if (wardType === WARD_TYPE.OUT) {
        sealType = this.signCNOptions.company ? SEAL_TYPE.OUT_ORG : SEAL_TYPE.OUT_PERSON;
      }
      eventManager.emit(NORMAL_SIG_FIELD_CREATE_SIGN_DATE_FIELD, { key: signDateStampKey, sigEle: this.eLabel, sealType })
    }
    toolBar.appendChild(span1)
    toolBar.appendChild(span2)
    window.pdfDocRender.eDoc.appendChild(toolBar);

    const pagePadEleRect = eLabel.parentNode.getBoundingClientRect();
    if (signEleRect.top - 50 < pagePadEleRect.top) {
      _updateToolBarPosition(toolBar, 'bottom', eLabel, pagePadEleRect);
    } else {
      _updateToolBarPosition(toolBar, 'top', eLabel, pagePadEleRect);
    }

    const toolBarRect = toolBar.getBoundingClientRect();
    if (toolBarRect.right > pagePadEleRect.right) {
      _updateToolBarPosition(toolBar,'left', eLabel, pagePadEleRect)
    }
    this.toolBarEle = toolBar;
  }

  getWardType() {
    return this.signCNOptions.companyId ? WARD_TYPE.IN : WARD_TYPE.OUT;
  }

  initSetAp() {
    const field = fieldsData.getField(this.widget.eSignAnnotKey);
    if (field && field.tempState && field.tempState.hasSetToPdf) {
      return;
    }
    if (field && field.tempState.ap) {
      this.selectSealFn({
        key: this.widget.eSignAnnotKey,
        base64: field.tempState.ap,
        isTemp: field.tempState.subParams.isTemp,
        origin: field.tempState.subParams.origin,
        setOkCallback:  field.tempState.subParams.setOkCallback,
        sealType: field.sealType
      })
      fieldsData.updateField(this.widget.eSignAnnotKey, { ap: field.tempState.ap, isPrimaryField: true })
    }
  }

  async setAp() {
    if (this.disable) {
      return;
    }
    const params = { key: this.widget.eSignAnnotKey, origin: FOXIT_FIELD_TYPE.NORMAL_SIG };
    const wardType = this.getWardType();
    if (wardType === WARD_TYPE.IN) {
      params.sealTypes = [SEAL_TYPE.IN_PERSON];
    } else {
      params.sealTypes = this.signCNOptions.company ? [SEAL_TYPE.OUT_ORG] : [SEAL_TYPE.OUT_PERSON];
    }
    eventManager.emit(SHOW_SEAL_ACTION_SHEET, params);
    // if (this.sealActionSheet) {
    //   // this.sealActionSheet.show();
    // }
  }

  selectSealFn({ key, base64, isTemp, origin, setOkCallback, sealType }) {

    const resetPagingSigDomStyle = (key) => {
      const targetObjNum = +key.split('_')[0];
      let pagingSigs = this.signCNOptions.annots?.find(annot => annot.pagingSigs.some(pagingSig => pagingSig.id === targetObjNum))?.pagingSigs || [];
      if(Array.isArray(pagingSigs) && pagingSigs.some(it => it.id === this.widget.objNum)) {
        this.nameSpan && (this.nameSpan.style.display = 'none')
      }
    }
    if (origin !== FOXIT_FIELD_TYPE.NORMAL_SIG) return;
    resetPagingSigDomStyle(key);

    let curSealType = SEAL_TYPE.IN_PERSON;
    const wardType = this.getWardType();
    if (wardType === WARD_TYPE.OUT) {
      curSealType = this.signCNOptions.company ? SEAL_TYPE.OUT_ORG : SEAL_TYPE.OUT_PERSON;
    }

    if (curSealType !== sealType) {
      return;
    }

    if(key !== this.widget.eSignAnnotKey) {
      if (this.hasSetAp) {
        return
      }
      const signConfig = sessionCache.getSignConfig();
      if(!signConfig.batchSetAp) {
        return;
      }
    }
    if (key === this.widget.eSignAnnotKey) {
      fieldsData.updateField(this.widget.eSignAnnotKey, { ap: base64, isPrimaryField: true, subParams: { isTemp, origin, setOkCallback } })
    } else {
      fieldsData.updateField(this.widget.eSignAnnotKey, { ap: base64, subParams: { isTemp, origin, setOkCallback } })
    }
    const buffer = this._base64ToArrayBuffer(base64);
    const pdfDocRender = this.pdfPageRender.pdfDocRender;
    let isPagingSig = false;
    let pagingSigPageIndexList = [];
    if (this.signCNOptions.annots && this.signCNOptions.annots.length > 0) {
      const annot = this.signCNOptions.annots.find(annot => Array.isArray(annot.pagingSigs) && annot.pagingSigs.length > 0 && annot.pagingSigs.map(i => `${i.id}_${i.pageIndex}`).includes(this.widget.eSignAnnotKey))
      if (annot && Object.keys(annot).length > 0) {
        isPagingSig = true;
        pagingSigPageIndexList = annot.pagingSigs.map(it => it.pageIndex);
      }
    }
    pdfDocRender.pdfViewer.workerFetch
      .request('doc/field/setSigAP', {
        doc: pdfDocRender.docId,
        annotObjNum: this.widget.objNum,
        pageIndex: this.widget.pageIndex,
        inkSignBuffer: buffer,
        isPagingSig
      })
      .then(() => {
        this.nameSpan && (this.nameSpan.style.display = 'none')
        this.redrawViewerByActionResult({ isSign: true, pagingSigPageIndexList });
        setOkCallback && setOkCallback({ pageIndex: this.widget.pageIndex, key: this.widget.objNum, isTemp })
        this.hasSetAp = true;
      });
  }

  async showDom() {
    if (this.disable) {
      return;
    }
    if (!this.hasSetAp) {
      this.setAp();
    } else {
      if (this.toolBarEle) {
        this.toolBarEle.style.visibility = "visible" ;
      }
    }
  }

  hideDom() {
    super.hideDom();
    this.eDom.style.display = 'none';
  }

  handleFieldDisabled(data) {
    super.handleFieldDisabled(data);
  }

  handleCheckFieldDisabledData() {
    const dataStr = sessionStorage.getItem('PDF_FIELD_DISABLED_DATA');
    if (dataStr) {
      const data = JSON.parse(dataStr);
      this.handleFieldDisabled(data);
    }
  }

  bindEvent() {
    super.bindEvent();

    const focusFn = () => {
      this.showDom();
    };
    const blurFn = () => {
      this.hideDom();
    };

    const clickFn = (e) => {
      if (this.disable) {
        e.preventDefault(); // mobile端 用户点击别人的文本域要禁止唤起键盘
        return;
      }
      if (!this.hasSetAp) {
        this.setAp();
      } else {
        if (this.toolBarEle) {
          this.toolBarEle.style.visibility = this.toolBarEle.style.visibility = "visible" ;
        }
      }
    };

    const handleShowToolbarFn = (e) => {
      const toolbarEle = this.toolBarEle;
      if (!toolbarEle) {
        return;
      }
      // 获取toolbarEle的visible状态
      const toolbarEleVisible = toolbarEle.style.visibility;
      if (toolbarEleVisible === 'visible') {
        if (!toolbarEle.contains(e.target) && !this.eLabel.contains(e.target)) {
          this.toolBarEle.style.visibility = "hidden" ;
        }
      }
    }

    this.eLabel.addEventListener('focus', focusFn);
    this.releases.push(() => this.eLabel.removeEventListener('focus', focusFn));
    this.eLabel.addEventListener('blur', blurFn);
    this.releases.push(() => this.eLabel.removeEventListener('blur', blurFn));
    this.eLabel.addEventListener('click', clickFn);
    this.releases.push(() => this.eLabel.removeEventListener('click', clickFn));
    document.addEventListener('touchstart', handleShowToolbarFn);
    this.releases.push(() => document.removeEventListener('touchstart', handleShowToolbarFn));
    eventManager.on(PDF_FIELD_DISABLED, this.handleFieldDisabled.bind(this));
    eventManager.on(SELECT_SEAL_STAMP, this.selectSealFn.bind(this));
    this.handleCheckFieldDisabledData()
  }

  _generateStampEle(src) {
    const clickFn = () => {
      const inkSignBuffer = this._base64ToArrayBuffer(imgEle.src);
      this._setField(inkSignBuffer);
      this.eLabel.blur();
    };

    const divEle = document.createElement('div');
    divEle.classList.add('item');

    const imgEle = document.createElement('img');
    imgEle.classList.add('img');
    imgEle.src = src;
    imgEle.draggable = false;
    imgEle.addEventListener('click', clickFn);
    this.releases.push(() => imgEle.removeEventListener('click', clickFn));
    divEle.appendChild(imgEle);
    return divEle;
  }

  _base64ToArrayBuffer(base64) {
    base64 = base64.replace(/^data:.*?base64,/, '');
    let bstr = window.atob(base64),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return u8arr;
  }
}
