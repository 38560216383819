import TextComponent from "./formComponents/TextComponent";
import {FIELD_READONLY} from "./formComponents/FIELD_FLAG";
import {SIGN, TEXT} from "./formComponents/FIELD_TYPE";
import SignComponent from "./formComponents/SignComponent";
import {getAnnotsOfPage, getPdfFields, getPdfFlowSignInfo} from "@/request/pdf";
import eventManager, {
  GET_FLOW_INFO_FROM_PDF,
  BATCH_SET_FIELD_VALUE,
  UPDATE_DOC_INFO,
  ALL_FIELD_HAS_FILLED
} from "@/utils/event";
import {getCurrentItem} from "../components/main/service";
import {base64ToArrayBufferUtil} from "@/utils/common";

function setDocInfo(pdfPageRender) {
  const pageInfo = {
    pageIndex: pdfPageRender.index,
    scale: pdfPageRender.scale,
    rotation: pdfPageRender.rotation,
    pdfHeight: pdfPageRender.height,
    pdfWidth: pdfPageRender.width,
  }
  eventManager.emit(UPDATE_DOC_INFO, pageInfo)
  // const pages = globalStore.docInfo.pages;
  // const index = pages.findIndex(page => page.pageIndex === pdfPageRender.index);
  // if (index > -1) {
  //   pages[index] = pageInfo;
  // } else {
  //   pages.push(pageInfo)
  // }
  //
  // action(() => {
  //   // globalStore.docInfo.curVisiblePageIndex = pdfPageRender.index;
  //   globalStore.docInfo.pages = [...pages]
  // })()
}

export default class FormFillPlugin {
  constructor(pdfDocRender) {
    this.pdfDocRender = pdfDocRender;
    this.workerFetch = this.pdfDocRender.pdfViewer.workerFetch;
    this.fields = null;
    this.flowInfo = null;
    this.components = [];
  }

  onDocOpened() {
    const scale = _computeFileScale();
    this.pdfDocRender.zoomTo(scale);
    getPdfFields(this.workerFetch, this.pdfDocRender.docId) // 改为类实例 workerFetch pdfDocRender.docId作为class属性
    .then(({fields}) => {
        this.fields = fields;
        return fields;
    })
    .then(() => {
        return getPdfFlowSignInfo(this.workerFetch, this.pdfDocRender.docId)
    })
    .then(flowInfo => {
        this.flowInfo = flowInfo;
        eventManager.emit(GET_FLOW_INFO_FROM_PDF, flowInfo);
    })

    eventManager.on(BATCH_SET_FIELD_VALUE, this.handleListenSetFieldEvent.bind(this))
  }

  onDocClosing() {
    eventManager.off(BATCH_SET_FIELD_VALUE, this.handleListenSetFieldEvent.bind(this))
  }

  onPageVisible(pdfPageRender) {
    if (this.components[pdfPageRender.index]) {
      return;
    }
    setDocInfo(pdfPageRender);
    getAnnotsOfPage(this.workerFetch, this.pdfDocRender.docId, pdfPageRender.index).then(annots => {
      const annotsToRender = annotFilterFn(annots, pdfPageRender, this.fields, this.flowInfo);
      annotsToRender.forEach(annot => {
        const annotFilter1 = (annot, field) => annot.eSignAnnotKey === field.eSignAnnotKey;
        const annotFilter2 = (annot, field) => Array.isArray(annot.pagingSigs) && annot.pagingSigs.length > 0 && annot.pagingSigs.map(i => `${i.id}_${i.pageIndex}`).includes(field.eSignAnnotKey)
        const eSignCNOption = this.flowInfo.receipts.find(item => item.annots.some(itemAnnot => annotFilter1(itemAnnot, annot) || annotFilter2(itemAnnot, annot)));
        this.createDoms(annot, pdfPageRender, eSignCNOption)
      })
    })
  }

  onPageInvisible(pdfPageRender) {
  }

  onPageZoomed(pdfPageRender) {
    const index = pdfPageRender.index;
    if (this.components[index]) {
      this.components[index].forEach(component => {
        component.changeRect();
      });
    }
  }

  createDoms(widget, pdfPageRender, signCNOptions) {
    let component;
    if (widget.fieldJSON.type === TEXT) {
      component = new TextComponent(pdfPageRender, widget, signCNOptions);
    }
    if (widget.fieldJSON.type === SIGN) {
      component = new SignComponent(pdfPageRender, widget, signCNOptions);
    }
    if (component) {
      const index = pdfPageRender.index;
      if (!this.components[index]) {
        this.components[index] = [];
      }
      this.components[index].push(component);
    }
  }


  getFieldName(objNum) {
    let name;
    this.fields.forEach(it => {
      if (it.controls && it.controls.some(control => control.objNum === objNum)) {
        name = it.name;
      }
    })
    return name;
  }

  handleListenSetFieldEvent(fields) {
    const fn = () => {
      const taskList = [];
      fields.forEach(field => {
        if (field.type === 'FullSignature') {
          if (field.tempState.ap) {
            const buffer = base64ToArrayBufferUtil(field.tempState.ap);
            const task = this.pdfDocRender.pdfViewer.workerFetch
              .request('doc/field/setSigAP', {
                doc: this.pdfDocRender.docId,
                annotObjNum: field.annotObjNum,
                pageIndex: field.pageIndex,
                inkSignBuffer: buffer,
                isPagingSig: field.isPagingSig
              })
            taskList.push(task);
          }
        }
        if (['Title', 'Date'].includes(field.type)) {
          const fieldName = this.getFieldName(field.annotObjNum);
          if (fieldName) {
            const task = this.pdfDocRender.pdfViewer.workerFetch.request('doc/field/set', {
              doc: this.pdfDocRender.docId,
              field: fieldName,
              set: { value: field.tempState.value }
            })
            taskList.push(task);
          }
        }
      })
      return Promise.all(taskList);
    }
    fn().then(() => {
      eventManager.emit(ALL_FIELD_HAS_FILLED)
    })
  }
}

function getWidgetWithFieldAnnots (widgets, fields, pageIndex) {
  let fieldNameMap = {};
  fields.forEach(function (field) {
    fieldNameMap[field.name] = field;
  });
  return widgets.map(function (widget) {
    widget.fieldJSON = fieldNameMap[widget.field] || {};
    widget.eSignAnnotKey = `${widget.objNum}_${pageIndex}`
    widget.pageIndex = pageIndex
    return widget;
  });
}

function annotFilterFn(annots, pdfPageRender, fields, flowInfo) { // pdfPageRender 改为 pageIndex
  const annotsToRender = [];
  let widgets = annots.filter(annot => annot.type === 20);
  widgets = getWidgetWithFieldAnnots(widgets, fields, pdfPageRender.index);
  widgets = widgets.filter(widget => !(widget.fieldJSON.flags & FIELD_READONLY))
  if (widgets.length < 1) {
    return [];
  }
  if (!flowInfo.receipts || flowInfo.receipts.length < 1) {
    return []
  }
  // const item = getCurrentUserItemFromCurrentOrderItems(flowInfo.receipts);
  const itemList = getCurrentItem(flowInfo.receipts);
  const ret = Array.isArray(itemList);
  const curAnnots = ret && itemList.map(it => it.annots).flat();
  if (!ret || !curAnnots || curAnnots.length < 1) { // 没有轮到当前用户签名 或者 没有属于当前用户的签署环节 或者 当前用户的签署环节是 不指定域签名环节
    return []
  }
  const annotKeyOfCurrentItem = []
  const annotKeyOfCurrentItemPagingSig = []
  curAnnots.forEach(annot => {
    annotKeyOfCurrentItem.push(annot.eSignAnnotKey)
    if (annot.isPagingSig) {
      annot.pagingSigs.forEach((sig, index) => {
        if (index > 0) {
          annotKeyOfCurrentItemPagingSig.push(`${sig.id}_${sig.pageIndex}`)
        }
      })
    }
  })
  annots.forEach(annot => {
    if (annotKeyOfCurrentItem.includes(annot.eSignAnnotKey)) {
      annotsToRender.push(annot);
    } else if (annotKeyOfCurrentItemPagingSig.includes(annot.eSignAnnotKey)) {
      annot.isSubPagingSig = true;
      annotsToRender.push(annot);
    }
  });
  return  annotsToRender;
}

function _computeFileScale () {
  const pdfFileOriginWidth = 812;
  const curDeviceWidth = window.innerWidth;
  const scale = curDeviceWidth / pdfFileOriginWidth;
  return (Math.floor(scale * 100) - 1) / 100;
}
