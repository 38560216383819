import Matrix from "@/utils/deviceRectReverse/Matrix";


/**
 *
 *  将annot的domRect换算为pdfRect的步骤
 *
 *  1. 获取annot所属页面的渲染层Canvas元素，获取annot对应的渲染层dom元素 调用 getElementDomRect(pageEle, annotEle) 获取annot的domRect
 *  2. 获取annot所属页面的scale, rotate, pdfHeight, pdfWidth, dpi 调用 reverseDeviceRect({domRect, scale, rotate, pdfHeight, pdfWidth, dpi}) 获取annot的pdfRect
 *
 *
**/

/**
 *
 *  @params
 *  pageEle: annot所属页面对应的渲染层Canvas元素
 *  annotEle: annot对应的渲染层dom元素
 *  @return
 *  domRect: {left, top, right, bottom}
**/
export function getElementDomRect(pageEle, annotEle) {
  const pageRect = pageEle.getBoundingClientRect();
  const annotRect = annotEle.getBoundingClientRect();
  const left = annotRect.left - pageRect.left;
  const top = annotRect.top - pageRect.top;
  const right = left + annotRect.width;
  const bottom = top + annotRect.height;
  return {
    top,
    right,
    bottom,
    left
  }
}

/**
 *
 *  @params
 *  domRect: {left, top, right, bottom}
 *  scale: 缩放比例
 *  rotate: 旋转角度
 *  pdfHeight: annot所属页面的高度，单位pt
 *  pdfWidth: annot所属页面的宽度，单位pt
 *  dpi: 屏幕dpi
 *
 * @return
 * pdfRect: [left, bottom, right, top]
 *
**/

export function reverseDeviceRect({domRect, scale, rotate, pdfHeight, pdfWidth}) {
  const dpi = getDPI();
  const pdfRect = reverseDeviceRectUtil({ rect: domRect, scale, rotate, pdfHeight, pdfWidth, dpi })
  return [pdfRect.left, pdfRect.bottom, pdfRect.right, pdfRect.top];
}


function reverseDeviceRectUtil ({rect, scale = 1, rotate = 0, pdfHeight, pdfWidth, dpi}) {
  let matrix = getDeviceMatrix(rotate, pdfHeight, pdfWidth, dpi);
  matrix.scale(scale, scale);
  matrix.setReverse();
  let [minx,miny,maxx,maxy] = matrix.transformRect(rect.left, rect.top, rect.right, rect.bottom);
  let left = Math.min(minx,maxx);
  let right = Math.max(minx,maxx);
  let top = Math.max(miny,maxy);
  let bottom = Math.min(miny,maxy);
  return {
    left,
    top,
    right,
    bottom,
  };
}

let ptValue = 72;
let _pxValueArr;

function getConvertRatio (i = 0, dpi) {
  if(!_pxValueArr) {
    _pxValueArr = [dpi.x, dpi.y];
  }
  return _pxValueArr[i];
}

function pt2px (pt, xOry = 0, dpi) {
  return pt * getConvertRatio(xOry, dpi) / ptValue;
}


function getDeviceMatrix (rotate = 0, pagePdfHeight, pagePdfWidth, dpi) {
  const defaultMatrix = [1, 0, 0, 1, -0, -0];
  let matrix = new Matrix(defaultMatrix);
  let height = pagePdfHeight;
  let width = pagePdfWidth;
  matrix.translate(0, -height);
  let pxScale = pt2px(1, 0, dpi);
  matrix.scale(pxScale, -pxScale);
  switch(rotate) {
    case 90:
      matrix.rotate(rotate / 180 * Math.PI);
      matrix.translate(pxScale * height, 0);
      break;
    case 180:
      matrix.rotate(rotate / 180 * Math.PI);
      matrix.translate(pxScale * width, pxScale * height);
      break;
    case 270:
      matrix.rotate(rotate / 180 * Math.PI);
      matrix.translate(0, pxScale * width);
      break;
  }
  return matrix;
}

function getDPI() {
  const arrDPI = new Array();
  if ( window.screen.deviceXDPI != undefined ) {
    arrDPI[0] = window.screen.deviceXDPI;
    arrDPI[1] = window.screen.deviceYDPI;
  }
  else {
    const tmpNode = document.createElement( "DIV" );
    tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
    document.body.appendChild( tmpNode );
    arrDPI[0] = parseInt( tmpNode.offsetWidth );
    arrDPI[1] = parseInt( tmpNode.offsetHeight );
    tmpNode.parentNode.removeChild( tmpNode );
  }
  return {x: arrDPI[0], y: arrDPI[1]};
}
