import {SEAL_TYPE} from "../constants";

export function getPdfFields(workerFetch, docId) {
  return workerFetch.request('doc/field/all', {
    doc: docId,
  });
}

export function getAnnotsOfPage(workerFetch, docId, pageIndex) {
  return workerFetch.request('doc/page/annot/all', {
    doc: docId,
    page: pageIndex,
  });
}

export function getPdfFlowSignInfo (workerFetch, docId){
  return workerFetch.request('doc/getAssignInfo', {
    doc: docId,
  }).then(info => {
    if (Array.isArray(info.receipts)) {
      info.receipts = info.receipts.map(item => {
        if (item.companyId) {
          item.sealType = SEAL_TYPE.IN_PERSON;
        } else {
          item.sealType = item.company ? SEAL_TYPE.OUT_ORG : SEAL_TYPE.OUT_PERSON;
        }
        if (Array.isArray(item.annots)) {
          item.annots = item.annots.map(annot => {
            annot.eSignAnnotKey = `${annot.annotObjNum}_${annot.pageIndex}`
            return annot;
          })
        }
        return item
      })
    }
    return info;
  })
}

export function startSign(workerFetch, docId, signInfo) {
  return workerFetch.request('doc/startSign', {
    doc: docId,
    signInfo
  })
}

export function fillSignData(workerFetch, docId, pdfSignedDataOffset, pdfFile, signDataBase64, pdfBuffers) {
  return workerFetch.request('doc/fillSignedData', {
    doc: docId,
    signedDataOffset: pdfSignedDataOffset,
    file: pdfFile,
    signData: signDataBase64,
    progress: workerFetch.register(function (buffer) {
      pdfBuffers.push(buffer);
    })
  }).then(() => {
    return pdfBuffers;
  })
}
