import { commonHttpClient as httpRequest } from './util'
import qs from 'qs';
import globalStore from '@/store/index';
import {baseUrl, stgBaseUrl, testBaseUrl} from "../constants";
import {getEnterpriseId, getToken} from "../service";
import {sessionCache} from "../utils/storage";


const an = 'Foxit PhantomPDF Online';
const host = window.location.origin;

function _getBaseUrl() {
  let url;
  if (host.includes('localhost') || host.includes('devcn')) {//本地环境 //测试环境
    url = testBaseUrl;
  } else if (host.includes("stg")) {
    url = stgBaseUrl;
  } else {
    url = baseUrl;
  }
  return url;
}


function _getUrl() {
  // const configUrlsCache = JSON.parse(localStorage.getItem('configUrls') || '{}');
  return globalStore.configUrls.casApiUrl;
}


function _getAccountUrl() {
  const configUrls = globalStore.configUrls || {};
  let url = configUrls.accountApiUrl
  if (!url) {
    url = (configUrls.cwsApiUrl).replace('pheecws', 'account');
  }
  return url;
}

export function getServerConfig() {
  let url = _getBaseUrl() + `/cpdfapi/v1/server/server-config?v=${new Date().valueOf()}`;
  return httpRequest.get({
    url
  })
}

export function login(redirectUrl = '') {
  const params = {
    from: 'esignCN',
    isEnterp: 1,
    al: 'zh_CN',
    av: '3.0.0.1',
    cv: '2.0.0.0',
    an,
    service: redirectUrl ? redirectUrl : host,
    hf: 1,
  };
  const url = `${_getAccountUrl()}/site/plugin-sign-in?${qs.stringify(params)}`;
  window.location.href = url;
}

export function getTokenByTicket(ticket, redirectUrl) {
  const casService = redirectUrl ? redirectUrl : host;
  const url =
    _getAccountUrl() +
    '/api/users/accessTokenBySt?casService=' +
    casService +
    '&casTicket=' +
    ticket +
    '&an=' +
    an;
  return httpRequest.get({
    url
  }).then(res => res.access_token)
}

export function getUserInfo() {
  const token = getToken();
  const url = `${_getAccountUrl()}/api/users?access-token=${token}&userNotifyType=1`;
  return httpRequest.get({
    url
  }).then(res => res[0])
}

export function logout(token, redirectUrl = host) {
  return _deleteToken(token).then(() => {
    const url = `${_getUrl()}/cas/logout?service=${redirectUrl}&time=${Date.now()}`;
    sessionCache.removeToken();
    window.location.replace(url);
  });
}

function _deleteToken(token) {
  const data = {
    access_token: token,
  };
  const url = `${_getAccountUrl()}/api/users/access_token`;
  return httpRequest.delete({
    url,
    data
  })
}

// 对内签署 jwtToken
export function getJwtToken() {
  const plugName = 'esignCN';
  const token = getToken();
  const enterpriseId = getEnterpriseId();
  const url = `${_getAccountUrl()}/api/users/jwt-by-token?accessToken=${token}&enterpriseId=${enterpriseId}&pluginName=${plugName}`;
  return httpRequest.get({ url }).then(data => {
    if (data && data.jwt) {
      return data.jwt;
    }
    return Promise.reject(data);
  })
}
