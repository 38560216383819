import {pt2px} from "../utils/px.module";
import eventManager, { UPDATE_FLOW_FIELD_STATUS} from "@/utils/event";
import {getFiledText} from "../utils";
import fieldsData from '@/components/main/flowData';



export default class BaseComponent {
  constructor(pdfPageRender, widget, signCNOptions) {
    this.signCNOptions = signCNOptions;
    this.pdfPageRender = pdfPageRender;
    this.widget = widget;
    this.field = widget.fieldJSON;
    this.releases = [];
    this.eLabel = null;
    this.eDom = null;
    this.nameSpan = null;
    this.isSetValue = false;
    this.disable = true;


    this.init();
  }

  init() {
    this.createDom();
    this.bindEvent();
  }

  createDom () {
    const pdfPageRender = this.pdfPageRender;
    const widget = this.widget;

    const ePagePad = pdfPageRender.ePagePad;

    const borderWidth = pt2px(widget.borderInfo.width) * pdfPageRender.scale;

    const eLabel = this.eLabel = document.createElement('label');
    const labelStyle = this.getRect();
    eLabel.classList.add('fx-field-label');
    eLabel.style.cssText = `position:absolute;left:${labelStyle.left}px;top:${labelStyle.top}px;width:${labelStyle.width}px;height:${labelStyle.height}px;padding:${borderWidth}px;`;

    const eSignAnnotKey = this.widget && this.widget.eSignAnnotKey;
    let curAnnotInfo = Array.isArray(this.signCNOptions.annots) && this.signCNOptions.annots.find(annot => annot.eSignAnnotKey === eSignAnnotKey)
    if (this.widget.isSubPagingSig) {
      curAnnotInfo = this.signCNOptions.annots.find(annot => annot.pagingSigs.some(pagingSig => pagingSig.id === this.widget.objNum))
    }
    const hasFilled = curAnnotInfo && curAnnotInfo.fieldStatus === 2;
    if (hasFilled) { // ESIGNCNRD-1198
      eLabel.classList.add('outline-hidden')
    }

    if (this.signCNOptions && this.signCNOptions.color) {
      eLabel.style.backgroundColor = this.signCNOptions.color + '28'; // 透明度
      eLabel.style.border = this.signCNOptions.color + ' 1px solid';
      eLabel.style.borderRadius = '4px'

      const renderFieldTextUI = (eLabel, labelStyle, curAnnotInfo) => {

        const span = document.createElement('span');
        const isCompany = !!this.signCNOptions.company;
        let text = span.innerText = getFiledText({ annotInfo: curAnnotInfo, isCompany, labelStyle });
        span.innerText = text;
        span.style.cssText = `line-height:12px; font-size:12px; position:absolute; color: white; padding: 3px; border-radius: 3px; color:` + this.signCNOptions.color + ';white-space:nowrap';
        if (labelStyle.width + 10 < labelStyle.height) {
          if (text.search(/[A-Za-z]+/) > -1) {
            span.style.cssText = `${span.style.cssText}; transform: rotate(90deg)`
          } else {
            span.style.cssText = `${span.style.cssText}; writing-mode: vertical-lr;`
          }
        }
        this.nameSpan = span
        eLabel.appendChild(span);
      }
      const notFill = curAnnotInfo && curAnnotInfo.fieldStatus === 0;
      if(notFill) {
        renderFieldTextUI(eLabel, labelStyle, curAnnotInfo)
      }
    }
    ePagePad.appendChild(eLabel);
    this.addEle2Window();
    // action(() => {
    //   globalStore.updateFormInstanceOptions = this.updateSignCNOptions
    // })();
  }

  addEle2Window(isDate = false) {
    if (!window.eDoms) {
      window.eDoms = {};
    }
    const eSignAnnotKey = this.widget.eSignAnnotKey;
    window.eDoms[eSignAnnotKey] = isDate ? this.eDom : this.eLabel;
  }

  updateSignCNOptions(options) {
    this.signCNOptions = options;
  }


  changeRect() {
    const labelStyle = this.getRect();
    this.eLabel.style.left = `${labelStyle.left}px`;
    this.eLabel.style.top = `${labelStyle.top}px`;
    this.eLabel.style.width = `${labelStyle.width}px`;
    this.eLabel.style.height = `${labelStyle.height}px`;
  }

  getRect() {
    const pdfPageRender = this.pdfPageRender;
    const widget = this.widget;

    const pdf2device = pdfPageRender.transformPDFToDevice();
    const rect = widget.rect;
    const [left, top, right, bottom] = pdf2device.transformRect(rect.left, rect.top, rect.right, rect.bottom);

    if(this.signCNOptions.company) {
      return {
          left: left - 10,
          top: top - 10,
          width: right - left + 20,
          height: bottom - top + 20,
      }
    }

    return {
      left,
      top,
      width: right - left,
      height: bottom - top,
    }
  }
  //
  // handleActiveField({ eSignAnnotKey, pageIndex }) {
  //   const pdfDocRender = this.pdfPageRender.pdfDocRender
  //   handleFieldClick({ eSignAnnotKey, pdfDocRender, pageIndex })
  // }

  bindEvent () {
    // eventManager.on(ACTIVE_FIELD, this.handleActiveField.bind(this))
  }

  showDom () {
    this.eDom.style.opacity = '';
    if (!this.isSetValue && this.nameSpan) {
      this.nameSpan.style.display = 'none'
    }

  }

  hideDom () {
    this.eDom.style.opacity = '0';
    if (!this.isSetValue && this.nameSpan) {
      this.nameSpan.style.display = ''
    }

  }

  valueChanged (value, ignoreCalcOrderFields = false, doRedrawViewer = true) {
    const field = this.field;
    if (!value) {
      value = '  '
    }
    this.setField(field.name, {value}).then(() => {
      if (value) {
        this.isSetValue = true
        this.nameSpan && (this.nameSpan.style.display = 'none')
      } else {
        this.isSetValue = false
        this.nameSpan && (this.nameSpan.style.display = '')
      }
      field.value = value;
      this.redrawViewerByActionResult({});
    });
  }

  setField (fieldName, option) {
    const pdfDocRender = this.pdfPageRender.pdfDocRender;
    return pdfDocRender.pdfViewer.workerFetch.request('doc/field/set', {
      doc: pdfDocRender.docId,
      field: fieldName,
      set: option
    }).then(() => {
        if (!option.value) {
          return Promise.resolve();
        }
        const rect = this.getRect();
        const canvasList = document.getElementsByClassName('fx-page-render-canvas');
        Array.prototype.forEach.call(canvasList, canvas => {
          const ctx = canvas.getContext('2d');
          ctx.clearRect(rect.left, rect.top, rect.width, rect.height);
        })
        return Promise.resolve()
    })
  }

  redrawViewerByActionResult ({isSign, pagingSigPageIndexList}) {
    fieldsData.updateFieldFillStatus(this.widget.eSignAnnotKey)
    eventManager.emit(UPDATE_FLOW_FIELD_STATUS);
    const pdfPageRender = this.pdfPageRender;
    const pdfDocRender = pdfPageRender.pdfDocRender;
    let pageIndexList = [];
    if (pagingSigPageIndexList && pagingSigPageIndexList.length > 0) {
        pageIndexList = pagingSigPageIndexList;
    } else {
      pageIndexList.push(pdfPageRender.index)
    }
    const firstPageIndex = pageIndexList[0];
    pdfDocRender.redraw({page: firstPageIndex }).then(() => {
      if (pageIndexList.length < 2) return;
      pageIndexList.slice(1).forEach((pageIndex) => { // 针对骑缝章副章更新外观
        pdfDocRender.redraw({page: pageIndex })
      })
    })
  }

  handleFieldDisabled(data) {
    this.disable = data.disabled;
    this.eDom.disabled = data.disabled;
  }

  destroy () {
    this.releases.forEach(fn => fn());
    this.releases = [];
  }
}
