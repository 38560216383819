import {FOXIT_FIELD_TYPE} from "../../constants";
import {sessionCache} from "@/utils/storage";

const initField = {
  annotObjNum: '',
  color: '',
  eSignAnnotKey: '',
  fieldStatus: '',
  foxitSignFieldType: '',
  isESignCNSignature: '',
  isPagingSig: '',
  pageIndex: '',
  pagingSigs: [],
  type: '',
  sealType: '',
  tempState: {
    ap: '', // 签名域外观
    value: '', // 日期域/文本域 内容
    hasSet: false, // 是否已经设置过值
    hasSetToPdf: false, // 是否写入到PDF中
  }
}

function genFields(annots) {
  const fields = [];
  if (Array.isArray(annots)) {
    annots.forEach(annot => {
      // 按照initField的属性，将annot中同样的属性赋值给对象 obj;
      const obj = {}
      Object.keys(initField).forEach(key => {
        if (key === 'tempState') {
          obj[key] = initField[key];
        } else {
          obj[key] = annot[key];
        }
      })
      fields.push(obj);
    })
  }
  return fields
}

class FieldsData {
  constructor() {
    this.hasInit = false;
    this.fieldsData = null; // 改为私有属性
    this.hasSetSig = false;
    this.hasSetDate = false;
  }
  initFlowFieldInfo(annots) {
    if (annots && annots.filter(annot => !!annot).length > 0) {
      this.fieldsData = genFields(annots);
      this.hasInit = true;
    }
  }
  getInitStatus() {
    return this.hasInit;
  }
  getFlowFieldInfo() {
    return this.fieldsData;
  }
  getField(key) {
    return this.fieldsData.find(field => field.eSignAnnotKey === key);
  }

  shouldBatchOperate({foxitSignFieldType}) {

    const signConfig = sessionCache.getSignConfig();
    if (foxitSignFieldType === FOXIT_FIELD_TYPE.NORMAL_SIG) {
      return signConfig.batchSetAp;
    }
    if (foxitSignFieldType === FOXIT_FIELD_TYPE.DATE) {
      return  signConfig.batchSetDate;
    }

  }

  updateFieldFillStatus(key) {
    this.fieldsData.forEach(field => {
      if (field.eSignAnnotKey === key) {
        field.tempState.hasSetToPdf = true;
      }
    })
  }
  updateField(key, { ap, value, isPrimaryField, subParams }) {
    const field = this.getField(key)

    if (!field) return; // 骑缝章副章
    const foxitSignFieldType = field.foxitSignFieldType;
    const sealType = field.sealType;


    if (foxitSignFieldType === FOXIT_FIELD_TYPE.TITLE) {
      this.fieldsData.forEach(field => {
        if (field.eSignAnnotKey === key) {
          field.tempState = { ap, value, hasSet: !!(ap || value) };
        }
      })
      return;
    }
    if (this.shouldBatchOperate({foxitSignFieldType, sealType})) {
      this.fieldsData.forEach(field => {
        if (
          [FOXIT_FIELD_TYPE.NORMAL_SIG, FOXIT_FIELD_TYPE.DATE].includes(field.foxitSignFieldType) &&
          field.foxitSignFieldType === foxitSignFieldType &&
          field.sealType === sealType &&
          !field.tempState.hasSet
        ) {
          field.tempState = { ap, value, hasSet: !!(ap || value) };
          if (field.tempState.hasSet) {
            field.tempState.subParams = subParams;
            field.tempState.isPrimaryField = isPrimaryField;
          }
        }
      })
    } else {
      this.fieldsData.forEach(field => {
        if (field.eSignAnnotKey === key) {
          field.tempState = { ap, value, hasSet: !!(ap || value), subParams, isPrimaryField };
        }
      })
    }
    if (foxitSignFieldType === FOXIT_FIELD_TYPE.NORMAL_SIG) {
      this.hasSetSig = true;
    }
    if (foxitSignFieldType === FOXIT_FIELD_TYPE.DATE) {
      this.hasSetDate = true;
    }
  }
}

export default new FieldsData();
