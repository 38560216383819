/**
 * 表单域类型
 * @namespace Constants.FIELD_TYPE
 */

/**
 * unsupported or invalid field.
 * @memberOf Constants.FIELD_TYPE
 * @default 0
 */
export const UNKNOWN = 0;
/**
 * push button without any value.
 * @memberOf Constants.FIELD_TYPE
 * @default 1
 */
export const PUSH_BUTTON = 1;
/**
 * a group of radio buttons, at most one item can be selected.
 * @memberOf Constants.FIELD_TYPE
 * @default 2
 */
export const RADIO = 2;
/**
 * check box with on/off states.
 * @memberOf Constants.FIELD_TYPE
 * @default 3
 */
export const CHECKBOX = 3;
/**
 * single line or multi-line texts.
 * @memberOf Constants.FIELD_TYPE
 * @default 4
 */
export const TEXT = 4;
/**
 * rich text (using XML to store rich text).
 * @memberOf Constants.FIELD_TYPE
 * @default 5
 */
export const RICH_TEXT = 5;
/**
 * file content.
 * @memberOf Constants.FIELD_TYPE
 * @default 6
 */
export const FILE = 6;
/**
 * list box, single or multiple selections.
 * @memberOf Constants.FIELD_TYPE
 * @default 7
 */
export const LISTBOX = 7;
/**
 * combo box with or without the edit box.
 * @memberOf Constants.FIELD_TYPE
 * @default 8
 */
export const COMBOBOX = 8;
/**
 * signature field.
 * @memberOf Constants.FIELD_TYPE
 * @default 9
 */
export const SIGN = 9;
/**
 * free text.
 * @memberOf Constants.FIELD_TYPE
 * @default 10
 */
export const FREETEXT = 10;
