
class EsignCnCalendar {
  constructor(anchor, valueChange = () => {}) {
    this.anchor = anchor;
    this.containerEle = null;
    this.form = null;
    this.tableSkeleton = null;
    this.targetTdEle = null;
    this.todayEle = null;
    this.year = null;
    this.month = null;
    this.selectDate = null;
    this.dayTable = null;
    this.handleSelectDate = valueChange

    this.init(anchor)
  }

  //日的数组
  constructorTable(anchor) {
    this.tableSkeleton = [
      {
        children: [
          {
            class: 'poin',
            tagName: "th",
            innerHTML: "<"
          },
          {
            id: 'title',
            tagName: "th",
            colspan: "5"
          },
          {
            class: 'poin',
            tagName: "th",
            innerHTML: ">"
          }
        ],
      },
      {
        id: 'days',
        children: [
          {
            tagName: "th",
            innerHTML: "日"
          },
          {
            tagName: "th",
            innerHTML: "一"
          },
          {
            tagName: "th",
            innerHTML: "二"
          },
          {
            tagName: "th",
            innerHTML: "三"
          },
          {
            tagName: "th",
            innerHTML: "四"
          },
          {
            tagName: "th",
            innerHTML: "五"
          },
          {
            tagName: "th",
            innerHTML: "六"
          }
        ],
      }
    ]
    for(let i = 0; i < 6; i++) {
      const tr = {
        children: []
      };
      for(let k = 0; k < 7; k++) {
        const td = {
          tagName: 'td',
          innerHTML: ''
        }
        tr.children.push(td)
      }
      this.tableSkeleton.push(tr)
    }
    this.containerEle = document.createElement('div');
    this.containerEle.classList.add('container');
    const tableEle = document.createElement('table');
    tableEle.setAttribute('id', 'calendar')
    this.tableSkeleton.forEach(tr => {
      const trEle = document.createElement('tr');
      if (tr.id) { trEle.setAttribute('id', tr.id); }
      if (tr.class) { trEle.setAttribute('class', tr.class); }
      tr.children.forEach(child => {
        let childEle = document.createElement(child.tagName);
        if (child.id) { childEle.setAttribute('id', child.id); }
        if (child.class) { childEle.setAttribute('class', child.class); }
        if (child.colspan) { childEle.setAttribute('colspan', child.colspan); }
        childEle.innerHTML = child.innerHTML;
        trEle.appendChild(childEle)
      })
      tableEle.appendChild(trEle)
    })
    this.containerEle.appendChild(tableEle)
    const divEle = document.createElement('div');
    divEle.setAttribute('class', 'toolBar');
    const cleanBtn = document.createElement('div')
    cleanBtn.setAttribute('id', 'cleanBtn');
    cleanBtn.setAttribute('colspan', 4);
    cleanBtn.setAttribute('align', 'left');
    cleanBtn.style.textAlign = 'left';
    cleanBtn.innerHTML = '清除'
    const todayBtn = document.createElement('div')
    todayBtn.setAttribute('id', 'todayBtn');
    todayBtn.setAttribute('colspan', 3);
    todayBtn.style.textAlign = 'right';
    todayBtn.innerHTML = '今天'
    divEle.appendChild(cleanBtn)
    divEle.appendChild(todayBtn)
    this.containerEle.appendChild(divEle)
    document.body.appendChild(this.containerEle)

    todayBtn.onclick = (e) => {

      this.clearCalendar(this.form)
      const currYear = (new Date()).getFullYear();
      const currMonth = (new Date()).getMonth();
      let targetDate=new Date(currYear,currMonth,1);
      this.createCalendar(this.form,targetDate)

      if (this.todayEle) {
        this.selectDay(this.todayEle)
      }
    }

    cleanBtn.onclick = (e) => {
      if (this.targetTdEle) {
        this.targetTdEle.classList.remove('active')
      }
      this.targetTdEle = null;
      this.handleSelectDate(null);
      setTimeout(() => {
        this.containerEle.style.display = 'none';
      }, 100)
    }

    return tableEle;
  }
  //初始化函数
  init(anchor) {
    this.form = this.constructorTable(anchor);
    // 1获取日数组
    this.dayTable=this.form.getElementsByTagName('td');
    //2创建日历,传入当前时间
    this.createCalendar(this.form,new Date());
    let nextMon=this.form.getElementsByTagName('th')[2];
    let preMon=this.form.getElementsByTagName('th')[0];
    nextMon.classList.add('common-cell')
    preMon.classList.add('common-cell')
    preMon.onclick=() => {
      // this.init(anchor);
      this.clearCalendar(this.form)
      let preDate=new Date(this.year,this.month-1,1);
      this.createCalendar(this.form,preDate)
    }
    nextMon.onclick=() => {
      // this.init(anchor);
      this.clearCalendar(this.form)
      let nextDate=new Date(this.year,this.month+1,1);
      this.createCalendar(this.form,nextDate)
    }

    this.form.onclick= (e) => {
      if (e.target.nodeName === 'TD') {
        if (e.target.dataValue) {
          this.selectDay(e.target);
        }
      }
      e.preventDefault();
      e.stopPropagation();
    }

    document.addEventListener('click', (e)=> {
      if (this.anchor.contains(e.target)) {
        return;
      }
      this.showDatePicker(false)
    })
  }
  selectDay(ele, showPicker = false) {
    if (this.targetTdEle) {
      this.targetTdEle.classList.remove("active")
    }
    ele.classList.add("active")
    this.targetTdEle = ele;
    this.selectDate = ele.dataValue;
    this.handleSelectDate(this.selectDate);
    !showPicker && setTimeout(() => {
      this.containerEle.style.display = 'none';
    }, 100)
  }
  //清除日历数据
  clearCalendar(form){
    let tds=form.getElementsByTagName('td');
    for (let i = 0; i < tds.length; i++) {
      tds[i].innerHTML='';
      tds[i].dataValue = '';
      // 清除今天的样式
      tds[i].id='';
    }
  }
  // 3生成日历
  // from table表格  date要创建的日期
  createCalendar(form,date){
    //获取此时的年份
    this.year=date.getFullYear();
    //获取此时的月份
    this.month=date.getMonth();

    //年份月份写入日历
    form.getElementsByTagName('th')[1].innerHTML = this.year+"年"+(this.month+1)+"月";
    //获取本月的天数
    let dataNum=this.getDateLen(this.year,this.month);
    let firstDay = this.getFristDay(this.year, this.month);
    // 循环将每一天的天数写入到日历中
    // 让i表示日期。
    let userSelectDay = parseInt(this.selectDate && this.selectDate.split('/').pop(), 10)
    if (userSelectDay && userSelectDay > dataNum) {
      userSelectDay = dataNum
    }
    for (let i = 1; i <= dataNum; i++) {
      this.dayTable[firstDay+i-1].innerHTML=i;
      this.dayTable[firstDay+i-1].dataValue=`${this.year}/${this.month+1}/${i}`
      this.dayTable[firstDay+i-1].classList.add('date-cell')
      let nowDate =new Date();
      if(i ==nowDate.getDate() && this.month ==nowDate.getMonth()&&this.year == nowDate.getFullYear()){
        //    将当期元素的id设置为today
        this.dayTable[i+firstDay-1].classList.add("today");
        this.todayEle = this.dayTable[i+firstDay-1]
      }
    }
    if (this.targetTdEle) {
      this.targetTdEle.classList.remove('active')
    }
    if (userSelectDay) {
      // this.targetTdEle = this.dayTable[firstDay+userSelectDay-1]
      // this.targetTdEle.classList.add("active")
      this.selectDay(this.dayTable[firstDay+userSelectDay-1], true)
    }

  }
  getActiveIndex(arr) {
    let activeIndex = -1;
    for(let i = 0; i<arr.length; i++) {
      if (arr[i].classList.contains('active')) {
        activeIndex = i;
      }
      // arr[i].classList.remove('active')
    }
    return activeIndex;
  }
  // 获取本月份的天数
  getDateLen(year,month){
    //获取下个月的第一天
    let nextMonth=new Date(year,month+1,1);
    // 设置下月第一天的小时-1，也就是上个月最后一天的小时数，随便减去一个值不要超过24小时
    nextMonth.setHours(nextMonth.getHours()-1);
    //获取此时下个月的日期,就是上个月最后一天.
    return nextMonth.getDate();
  }
  // 获取本月第一天为星期几。
  getFristDay(year,month){
    let fristDay=new Date(year,month,1);
    return fristDay.getDay();
  }
  showDatePicker(bool = true) {
    this.containerEle.style.display = bool ? 'block' : 'none';
  }
}


export default EsignCnCalendar
