export function handleFieldClick({ eSignAnnotKey, pdfDocRender, pageIndex }) {
  pdfDocRender.goToPage(pageIndex).then(() => {
    setTimeout(() => {
      // 页面跳转完成后，dom初始化可能未完成，等待200ms
      const list = window.eDoms;
      if (list && Object.keys(list).length > 0) {
        const dom = list[eSignAnnotKey];
        if (dom) {
          dom.click();
          dom.focus();
          if (dom.showPicker) {
            dom.showPicker();
          }
          return true;
        }
      }
    }, 200);
  });
}

export function parseDateFormat (javascript) {
  if (javascript.indexOf('AFDate_Format') !== -1) {
    const reg = /AFDate_Format(?:Ex)?\("(.*?)"\)/;
    const match = javascript.match(reg);
    if (match && match.length === 2) {
      return match[1].trim();
    }
  }
  return '';
}

export function getFiledText ({ annotInfo, isCompany, labelStyle }) {
  let text = "";
  const FULL = 1; const SIMPLIFY = 2; const NO_TEXT = 3;
  let textLength = FULL

  if (labelStyle.width + 10 < labelStyle.height) {
    if (labelStyle.height < 68) {
      textLength = SIMPLIFY;
    }
    if (labelStyle.height < 96 && isCompany) {
      textLength = SIMPLIFY;
    }

    if (labelStyle.width < 15) {
      textLength = NO_TEXT;
    }
  } else {
    if (labelStyle.width < 68) {
      textLength = SIMPLIFY;
    }
    if (labelStyle.width < 75 && isCompany) {
      textLength = SIMPLIFY;
    }
    if (labelStyle.width < 30) {
      textLength = NO_TEXT;
    }
    if (labelStyle.width < 36 & isCompany) {
      textLength = NO_TEXT;
    }
    if ( labelStyle.height < 15) {
      textLength = NO_TEXT
    }
  }

  if (annotInfo.type === "Title") {
    text =  textLength === SIMPLIFY ? "内容" : "请输入内容";
    switch (textLength) {
      case SIMPLIFY:
        text = "内容";
        break;
      case NO_TEXT:
        text = "";
        break;
      default:
        text = "请输入内容";
        break;
    }
  }
  if (annotInfo.type === "Date") {
    text = textLength === SIMPLIFY ? "日期" : "请选择日期";
    switch (textLength) {
      case SIMPLIFY:
        text = "日期";
        break;
      case NO_TEXT:
        text = "";
        break;
      default:
        text = "请选择日期";
        break;
    }
  }
  if (annotInfo.type === "FullSignature") {
    text = textLength === SIMPLIFY ? "签章" : "请放置签章";
    switch (textLength) {
      case SIMPLIFY:
        text = "签章";
        break;
      case NO_TEXT:
        text = "";
        break;
      default:
        text = "请放置签章";
        break;
    }
    if (annotInfo.isPagingSig) {
      switch (textLength) {
        case SIMPLIFY:
          text = "骑缝章";
          break;
        case NO_TEXT:
          text = "";
          break;
        default:
          text = "请放置骑缝章";
          break;
      }
    }
    if (annotInfo.type === "FullSignature" && isCompany && text) {
      text = text + '[企]';
    }
  }
  return text;
}
