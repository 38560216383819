import {SIGN_DATE_FORMAT} from "../constants";

export const CACHE_KEY = {
  TOKEN: 'token',
  PROCESS_ID: 'process_id',
  ENTERPRISE_ID: 'enterprise_id',
  ENTERPRISE_NAME: 'enterprise_name',
  SUPPLIER_ID: 'supplierId',
  AUTH_RESULT: 'auth_result',
  OUT_SIGN_STAMP_LIST: 'out_sign_stamp_list', // 对外不指定域/签署日期stampList，由于对外签名需要调整到意愿认证，需要需要在跳转前后借助本地缓存确保stamp数据不丢失
  AUTH_PLUGIN_HOST: 'authPluginHost',
  CF: 'cf', // 实名认证活动推广统计标记
  SAFE_AREA_HEIGHT: 'safe_area_height',
  SIGN_CONFIG: 'sign_config'
}

class ESignCacheTool {
  constructor(storageName = 'sessionStorage') {
    this.storage = window[storageName];
  }
  setItem(key, value) {
    this.storage.setItem(key, value);
  }
  getItem(key) {
    return this.storage.getItem(key);
  }
  removeItem(key) {
    this.storage.removeItem(key);
  }
  clear() {
    this.storage.clear();
  }
  setToken(value) {
    this.setItem(CACHE_KEY.TOKEN, value);
  }
  getToken() {
    return this.getItem(CACHE_KEY.TOKEN);
  }
  removeToken() {
    this.removeItem(CACHE_KEY.TOKEN);
  }
  setSupplierId(id) {
    this.setItem(CACHE_KEY.SUPPLIER_ID, id);
  }
  getSupplierId() {
    return this.getItem(CACHE_KEY.SUPPLIER_ID);
  }
  setEnterpriseId(id) {
    this.setItem(CACHE_KEY.ENTERPRISE_ID, id);
  }
  getEnterpriseId() {
    return this.getItem(CACHE_KEY.ENTERPRISE_ID);
  }
  removeEnterpriseId() {
    this.removeItem(CACHE_KEY.ENTERPRISE_ID);
  }
  setEnterpriseName(name) {
    return this.setItem(CACHE_KEY.ENTERPRISE_NAME, name);
  }
  getEnterpriseName() {
    return this.getItem(CACHE_KEY.ENTERPRISE_NAME);
  }
  removeEnterpriseName() {
    this.removeItem(CACHE_KEY.ENTERPRISE_NAME)
  }
  setProcessId(id) {
    this.setItem(CACHE_KEY.PROCESS_ID, id);
  }
  getProcessId() {
    return this.getItem(CACHE_KEY.PROCESS_ID);
  }
  setAuthResult({pass}) {
    this.setItem(CACHE_KEY.AUTH_RESULT, JSON.stringify({pass, processId: sessionCache.getProcessId()}));
  }
  getAuthResult() {
    const dataStr = this.getItem(CACHE_KEY.AUTH_RESULT);
    if(dataStr) {
      const data = JSON.parse(dataStr);
      if(data.processId === sessionCache.getProcessId()) {
        return data;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  removeAuthResult() {
    this.removeItem(CACHE_KEY.AUTH_RESULT);
  }

  setOutSignStampList(stampList) {
    this.setItem(CACHE_KEY.OUT_SIGN_STAMP_LIST, JSON.stringify(stampList));
  }
  getOutSignStampList() {
    const val = this.getItem(CACHE_KEY.OUT_SIGN_STAMP_LIST);
    if (val) {
      return JSON.parse(val);
    } else {
      return val;
    }
  }
  removeOutSignStampList() {
    this.removeItem(CACHE_KEY.OUT_SIGN_STAMP_LIST);
  }
  setAuthPluginHost(host) {
    this.setItem(CACHE_KEY.AUTH_PLUGIN_HOST, host);
  }
  getAuthPluginHost() {
    return this.getItem(CACHE_KEY.AUTH_PLUGIN_HOST);
  }
  removeAuthPluginHost() {
    this.removeItem(CACHE_KEY.AUTH_PLUGIN_HOST);
  }
  setCF(val) {
    this.setItem(CACHE_KEY.CF, val)
  }
  getCF() {
    return this.getItem(CACHE_KEY.CF);
  }
  removeCF() {
    this.removeItem(CACHE_KEY.CF)
  }
  setSafeAreaHeight(height) {
    this.setItem(CACHE_KEY.SAFE_AREA_HEIGHT, height);
  }
  getSafeAreaHeight() {
    return this.getItem(CACHE_KEY.SAFE_AREA_HEIGHT);
  }
  setSignConfig(config) {
    const originConfig = this.getSignConfig() || {};
    this.setItem(CACHE_KEY.SIGN_CONFIG, JSON.stringify({...originConfig, ...config}));
  }
  setHasShowAutoAddDateTipFn() {
    const originConfig = this.getSignConfig() || {};
    originConfig.hasShowAutoAddDateTips = true;
    this.setItem(CACHE_KEY.SIGN_CONFIG, JSON.stringify(originConfig));
  }
  getSignConfig() {
    const val = this.getItem(CACHE_KEY.SIGN_CONFIG);
    if (val) {
      return JSON.parse(val);
    } else {
      return {
        batchSetAp: true,
        batchSetDate: true,
        autoAddDate: false,
        signDateFormat: SIGN_DATE_FORMAT.FORMAT1,
        hasShowAutoAddDateTips: false
      };
    }
  }
}

export const sessionCache = new ESignCacheTool('localStorage');
