function _getXDPI () {
    if (typeof window.screen !== 'undefined' && window.screen.deviceXDPI) {
        return window.screen.deviceXDPI;
    } else if(typeof document !== 'undefined') { // worker
        let tempNode = document.createElement('div');
        tempNode.style.cssText = 'width:1in;height:1in;position:absolute;top:20in;border:none;padding:0;';
        document.documentElement.appendChild(tempNode);
        let dpi = parseInt(tempNode.offsetWidth, 10);
        tempNode.parentNode.removeChild(tempNode);
        return dpi;
    }
    return 96;
}

const XDPI = _getXDPI();

function getDPR () {
    //let isIPhone = navigator.appVersion.match(/iphone/gi);
    let dpr = typeof devicePixelRatio !== 'undefined' ? devicePixelRatio : 1;
    //if (isIPhone) {
    //    dpr = Math.min(2, dpr);
    //}
    return dpr;
}
let dpr = getDPR();

function pt2px (pt) {
    return pt * XDPI / 72;
}

export {
    dpr,
    pt2px
};
