/**
 * @brief PDF matrix for coordinate transformation
 * @ingroup ViewerPDF
 */
class Matrix {
    /**
     * Constructors for Matrix
     * @param a number - First construct parameter, could be 
     *                  the input coefficient a, a other Matrix object 
     *                  or an array with 6 number elements.
     * @param b number - The input coefficient b.
     * @param c number - The input coefficient c.
     * @param d number - The input coefficient d.
     * @param e number - The input coefficient e.
     * @param f number - The input coefficient f.
     * @par Example:
     @code{.js}
        function example () {
            let matrix1 = new Matrix();//default matrix which no effects.
            let matrix2 = new Matrix(2,0,0,1.5,0,0);//a matrix which magnified 2 times horizontally and 1.5 times vertically
            let matrix3 = new Matrix(matrix2);//a matrix copied from matrix2.
        }
     @endcode
     */
    constructor (a = 1, b  = 0, c = 0, d = 1, e = 0, f = 0) {
        if (typeof a.transformRect=='function') {
            let _a = a;
            a = _a.a;
            b = _a.b;
            c = _a.c;
            d = _a.d;
            e = _a.e;
            f = _a.f;
        } else if (Array.isArray(a)) {
            let _a = a;
            a = _a[0];
            b = _a[1];
            c = _a[2];
            d = _a[3];
            e = _a[4];
            f = _a[5];
        }
        this.a = a;
        this.b = b;
        this.c = c;
        this.d = d;
        this.e = e;
        this.f = f;

    }
    /**
     * Concat two matrices. It means to get the result of matrix1*matrix2.
     * @param matrix1 Matrix - Matrix one.
     * @param matrix2 Matrix - Matrix two. 
     * @returns Matrix - The result of matrix1*matrix2.
     */
    static Concat2mt (matrix1, matrix2) {
        let aa = matrix1.a * matrix2.a + matrix1.b * matrix2.c;
        let bb = matrix1.a * matrix2.b + matrix1.b * matrix2.d;
        let cc = matrix1.c * matrix2.a + matrix1.d * matrix2.c;
        let dd = matrix1.c * matrix2.b + matrix1.d * matrix2.d;
        let ee = matrix1.e * matrix2.a + matrix1.f * matrix2.c + matrix2.e;
        let ff = matrix1.e * matrix2.b + matrix1.f * matrix2.d + matrix2.f;
        return new Matrix(aa, bb, cc, dd, ee, ff);
    }
    /**
     * @brief
     * Set matrix's coefficient.
     * @param a number - The input coefficient a.
     * @param b number - The input coefficient b.
     * @param c number - The input coefficient c.
     * @param d number - The input coefficient d.
     * @param e number - The input coefficient e.
     * @param f number - The input coefficient f.
     */
    set (a, b, c, d, e, f) {
        this.a = a;
        this.b = b;
        this.c = c;
        this.d = d;
        this.e = e;
        this.f = f;
    }
    /**
     * Reset matrix to [1,0,0,1,0,0].
     */
    reset () {
        this.a = 1;
        this.b = 0;
        this.c = 0;
        this.d = 1;
        this.e = 0;
        this.f = 0;
    }
    /**
     * Get inversed matrix of another matrix. 
     * @param matrix Matrix - Input matrix.
     * @returns Matrix - Inversed matrix
     */
    reverse (matrix = this) {
        let i = matrix.a * matrix.d - matrix.b * matrix.c;
        if (i  == 0) return matrix;
        let j = -i;
        let a = matrix.d / i;
        let b = matrix.b / j;
        let c = matrix.c / j;
        let d = matrix.a / i;
        let e = (matrix.c * matrix.f - matrix.d * matrix.e) / i;
        let f = (matrix.a * matrix.f - matrix.b * matrix.e) / j;
        return new Matrix(a, b, c, d, e, f);
    }
    /**
     * @brief
     * Set the coefficients of the inverse of another matrix to this matrix. 
     * @param matrix Matrix - Input matrix.
     */
    setReverse (martix) {
        let reverseMatrix = this.reverse(martix);
        this.set(reverseMatrix.a, reverseMatrix.b, reverseMatrix.c, reverseMatrix.d, reverseMatrix.e, reverseMatrix.f);
    }
    /**
     * @brief
     * Rotate the matrix.
     * @param fRadian number - Rotation angle in radian. 
     * @param bPrepended boolean - If it's TRUE, a rotation matrix is multiplied at left side, or at right side.
     */
    rotate (fRadian, bPrepended) {
        let cosValue = Math.cos(fRadian);
        let sinValue = Math.sin(fRadian);
        let matrix = new Matrix(cosValue, sinValue, -sinValue, cosValue, 0, 0);
        let rel;
        if (bPrepended) {
            rel = Matrix.Concat2mt(matrix, this);
        } else {
            rel = Matrix.Concat2mt(this, matrix);
        }
        this.set(rel.a, rel.b, rel.c, rel.d, rel.e, rel.f);
    }
    /**
     * @brief
     * Rotate the matrix at a position. 
     * @param dx number - The x coordinate from which to rotate. 
     * @param dy number - The y coordinate from which to rotate. 
     * @param fRadian number - Rotation angle in radian. 
     * @param bPrepended boolean - If this is TRUE, a rotation matrix is multiplied at left side, or at right side
     */
    rotateAt (dx, dy, fRadian, bPrepended) {
        this.translate(dx, dy, bPrepended);
        this.rotate(fRadian, bPrepended);
        this.translate(-dx, -dy, bPrepended);
    }
    /**
     * @brief
     * Translate the matrix. 
     * @param x number - The x-direction delta value.
     * @param y number - The y-direction delta value. 
     * @param bPrepended boolean - If this is TRUE, a translation matrix is multiplied at left side, or at right side.
     */
    translate (x, y, bPrepended) {
        if(bPrepended) {
            this.e += x * this.a + y * this.c;
            this.f += y * this.d + x * this.b;
        } else {
            this.e += x;
            this.f += y;
        }
    }
    /**
     * @brief
     * Concatenate with another matrix. 
     * @param a number|Matrix|[number,number,number,number,number,number] - First parameter,should be one of input coefficient a,
     *                                  the matrix to be concatenated or an array with 6 number elements.
     * @param b number - The input coefficient b.
     * @param c number - The input coefficient c.
     * @param d number - The input coefficient d.
     * @param e number - The input coefficient e.
     * @param f number - The input coefficient f.
     * @param bPrepended boolean -
     */
    concat (a, b, c, d, e, f, bPrepended) {
        let matrix;
        if (a instanceof Matrix) {
            matrix = a;
            bPrepended = b;
        } else {
            matrix = new Matrix(a, b, c, d, e, f);
        }
        let rel;
        if (bPrepended) {
            rel = Matrix.Concat2mt(matrix, this);
        } else {
            rel = Matrix.Concat2mt(this, matrix);
        }
        this.set(rel.a, rel.b, rel.c, rel.d, rel.e, rel.f);
    }
    /**
     * @brief
     * Current matrix is rotated 90 degrees.
     */
    is90Rotated () {
        let a1 = this.a * 1000;
        if (a1 < 0) a1 *= -1;
        let d1 = this.d * 1000;
        if (d1 < 0) d1 *= -1;
        let b1 = this.b;
        if (b1 < 0) b1 *= -1;
        let c1 = this.c;
        if (c1 < 0) c1 *= -1;
        return (a1 < b1) && (d1 < c1);
    }
    /**
     * @brief
     * Current matrix is scaled.
     */
    isScaled () {
        let b1 = this.b * 1000;
        if (b1 < 0) b1 *= -1;
        let c1 = this.c * 1000;
        if (c1 < 0) c1 *= -1;
        let a1 = this.a;
        if (a1 < 0) a1 *= -1;
        let d1 = this.d;
        if (d1 < 0) d1 *= -1;
        return (b1 < a1) && (c1 < d1);
    }
    /**
     * @brief
     * Get coefficient a.
     * @returns number - 
     */
    getA () { return this.a; };
    /**
     * @brief
     * Get coefficient b.
     * @returns number - 
     */
    getB () { return this.b; };
    /**
     * @brief
     * Get coefficient c.
     * @returns number - 
     */
    getC () { return this.c; };
    /**
     * @brief
     * Get coefficient d.
     * @returns number - 
     */
    getD () { return this.d; };
    /**
     * @brief
     * Get coefficient e.
     * @returns number - 
     */
    getE () { return this.e; };
    /**
     * @brief
     * Get coefficient f.
     * @returns number - 
     */
    getF () { return this.f; };
    /**
     * @brief
     * Scale current matrix.
     * @param sx number - The x-direction scale coefficient. 
     * @param sy number - The y-direction scale coefficient. 
     * @param bPrepended boolean - If this is TRUE, a scaling matrix is multiplied at left side, or at right side.
     */
    scale (sx, sy, bPrepended) {
        this.a *= sx;
        this.d *= sy;
        if (bPrepended) {
            this.b *= sx;
            this.c *= sy;
        }
        else {
            this.b *= sy;
            this.c *= sx;
            this.e *= sx;
            this.f *= sy;
        }
    }
    /**
     * @brief
     * Transform x-direction distance.
     * @param dx number - X-direction distance
     * @returns number - The transformed distance. 
     */
    transformXDistance (dx) {
        let fx = this.a * dx;
        let fy = this.b * dx;
        return Math.sqrt(fx * fx + fy * fy);
    }
    /**
     * @brief
     * Transform x-direction distance.
     * @param dy number - Y-direction distance
     * @returns number - The transformed distance. 
     */
    transformYDistance (dy) {
        let fx = this.c * dy;
        let fy = this.d * dy;
        return Math.sqrt(fx * fx + fy * fy);
    }
    /**
     * @brief
     * Transform distance.
     * @param dx number - X-direction distance
     * @param dy number - Y-direction distance
     * @returns number - The transformed distance. 
     */
    transformDistance (dx, dy) {
        let fx = this.a * dx + this.c * dy;
        let fy = this.b * dx + this.d * dy;
        return Math.sqrt(fx * fx + fy * fy);
    }
    /**
     * @brief
     * Transform point.
     * @param dx number - Horizen coordinary value of point.
     * @param dy number - Vertical coordinary value of point.
     * @returns number[] - The transformed point. 
     */
    transformPoint (x, y) {
        let fx = this.a * x + this.c * y + this.e;
        let fy = this.b * x + this.d * y + this.f;
        //return [parseFloat(fx.toFixed(3)), parseFloat(fy.toFixed(3))];
        return [fx, fy];
    }
    /**
     * @brief
     * Transform a rectangle and return a bounding rectangle.
     * @param left number - Left of rectangle.
     * @param top number - Top of rectangle.
     * @param right number - Right of rectangle.
     * @param bottom number - Bottom of rectangle.
     * @returns number[] - The transformed rectangle.
     */
    transformRect (left, top, right, bottom) {
        let x = [left, left, right, right], y = [top, bottom, top, bottom];
        let point;
        for (let i = 0; i < 4; i++) {
            point = this.transformPoint(x[i], y[i]);
            x[i] = point[0]; y[i] = point[1];
        }
        // rel[0]: left  rel[1]: right
        // rel[2]: top   rel[3]: bottom
        let rel = [x[0], x[0], y[0], y[0]];
        for (let i = 1; i < 4; i++) {
            if (rel[0] > x[i]) rel[0] = x[i];
            if (rel[1] < x[i]) rel[1] = x[i];
            if (rel[2] > y[i]) rel[2] = y[i];
            if (rel[3] < y[i]) rel[3] = y[i];
        }
        let rst = [
            rel[0],
            rel[2],
            rel[1],
            rel[3]
        ];
        // rst[0]: left    rst[1]: top
        // rst[2]: right   rst[3]: bottom
        return rst;
    }
    /**
     * @brief
     * Get the x-direction unit size. 
     * @returns number - The x-direction unit size. 
     */
    getXUnit () {
        let a = this.a;
        let b = this.b;
        if (b===0) {
            return Math.abs(a);
        }
        else if (a===0) {
            return Math.abs(b);
        }
        else {
            return Math.sqrt(a*a+b*b);
        }
    }
    /**
     * @brief
     * Get the y-direction unit size. 
     * @returns number - The y-direction unit size. 
     */
    getYUnit () {
        let c = this.c;
        let d = this.d;
        if (c===0) {
            return Math.abs(d);
        }
        else if (d===0) {
            return Math.abs(c);
        }
        else {
            return Math.sqrt(c*c+d*d);
        }
    }
    /**
     * @brief
     * Get a bounding rectangle of the parallelogram composing two unit vectors. 
     * @returns number[] - The unit rectangle.
     */
    getUnitRect () {
        return this.transformRect(0, 0, 1, 1);
    }
    /**
     * @brief
     * Get a matrix that transforms a source rectangle to dest rectangle. 
     * @param dest number[] - The dest rectangle. 
     * @param src number[] - The source rectangle.
     */
    matchRect (dest, src) {
        let fDiff = src[0] - src[2];
        this.a = Math.abs(fDiff) < 0.001 ? 1 : (dest[0] - dest[2]) / fDiff;
        fDiff = src[3] - src[1];
        this.d = Math.abs(fDiff) < 0.001 ? 1 : (dest[3] - dest[1]) / fDiff;
        this.e = dest[0] - src[0] * this.a;
        this.f = dest[3] - src[3] * this.d;

        this.b = 0;
        this.c = 0;
    }
    /**
     * @brief
     * Get rotated angle.
     * @returns number - Rotated angle.
     */
    getAngle () {
        return Math.atan2(this.b, this.a);
    }
    toArray () {
        return [this.a, this.b, this.c, this.d, this.e, this.f];
    }
}

export default Matrix;