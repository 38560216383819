import '@tarojs/plugin-platform-h5/dist/runtime'

import { initPxTransform } from '@tarojs/taro'
import { createRouter } from '@tarojs/router'
import component from "./app"
import { window } from '@tarojs/runtime'
import { createReactApp } from '@tarojs/plugin-framework-react/dist/runtime'

import * as React from 'react'
import ReactDOM from 'react-dom'

import { defineCustomElementTaroPullToRefresh } from '@tarojs/components/dist/components'


var config = {"router":{"customRoutes":{"/pages/index/index":"/","/pages/demo/demo":"/demo","/pages/ad-marketing-activities/ad-marketing-activities":"/ad"},"mode":"browser"},"pages":["pages/index/index","pages/sign-result/sign-result","pages/flow-refuse/index","pages/flow-revoke/index","pages/ad-marketing-activities/ad-marketing-activities","pages/ad-marketing-activities/auth-result","pages/auth-result/auth-result"],"window":{"backgroundTextStyle":"light","navigationBarBackgroundColor":"#fff","navigationBarTitleText":"WeChat","navigationBarTextStyle":"black"}}
window.__taroAppConfig = config

if (config.tabBar) {
  var tabbarList = config.tabBar.list
  for (var i = 0; i < tabbarList.length; i++) {
    var t = tabbarList[i]
    if (t.iconPath) {
      t.iconPath = tabbarIconPath[i]
    }
    if (t.selectedIconPath) {
      t.selectedIconPath = tabbarSelectedIconPath[i]
    }
  }
}
config.routes = [
  Object.assign({
  path: 'pages/index/index',
  load: function(context, params) {
    const page = import("./pages/index/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"福昕电子签章"}),Object.assign({
  path: 'pages/sign-result/sign-result',
  load: function(context, params) {
    const page = import("./pages/sign-result/sign-result")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"签署完成"}),Object.assign({
  path: 'pages/flow-refuse/index',
  load: function(context, params) {
    const page = import("./pages/flow-refuse/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"福昕电子签章"}),Object.assign({
  path: 'pages/flow-revoke/index',
  load: function(context, params) {
    const page = import("./pages/flow-revoke/index")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"福昕电子签章"}),Object.assign({
  path: 'pages/ad-marketing-activities/ad-marketing-activities',
  load: function(context, params) {
    const page = import("./pages/ad-marketing-activities/ad-marketing-activities")
    return [page, context, params]
  }
}, {"navigationBarTitleText":"个人实名认证"}),Object.assign({
  path: 'pages/ad-marketing-activities/auth-result',
  load: function(context, params) {
    const page = import("./pages/ad-marketing-activities/auth-result")
    return [page, context, params]
  }
}, {}),Object.assign({
  path: 'pages/auth-result/auth-result',
  load: function(context, params) {
    const page = import("./pages/auth-result/auth-result")
    return [page, context, params]
  }
}, {})
]
defineCustomElementTaroPullToRefresh()

var inst = createReactApp(component, React, ReactDOM, config)
createRouter(inst, config, React)
initPxTransform({
  designWidth: 375,
  deviceRatio: {"375":2,"640":1.17,"750":1,"828":0.905},
  baseFontSize: 20,
  unitPrecision: undefined,
  targetUnit: undefined
})
