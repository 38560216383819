
// additional actions defined in an annotation's dictionary.
export const CURSOR_ENTER = 0; // < E, cursor enters annotation, arbitrary action.
export const CURSOR_EXIT = 1;			// < X, cursor exits annotation, arbitrary action.
export const BUTTON_DOWN = 2;			// < D, mouse button is pressed, arbitrary action.
export const BUTTON_UP = 3;				// < U, mouse button is released, arbitrary action.
export const GET_FOCUS = 4;				// < Fo, annotation get input focus, arbitrary action.
export const LOSE_FOCUS = 5;				// < Bl, annotation loses input focus, arbitrary action.
export const PAGE_OPEN = 6;				// < PO, page is opened, executed after O, arbitrary action.
export const PAGE_CLOSE = 7;				// < PC, page is closed, executed before C, arbitrary action.
export const PAGE_VISIBLE = 8;			// < PV, page becomes visible, arbitrary action.
export const PAGE_INVISIBLE = 9;		// < PI, page is no longer visible, arbitrary action.

// additional actions defined in a page's dictionary.
export const OPEN_PAGE = 10;				// < O, page is opened, arbitrary action.
export const CLOSE_PAGE = 11;			// < C, page is closed, arbitrary action.

// additional actions defined in a form field's dictionary.
export const KEY_STROKE = 12;			// < K, user types a key-stroke into field, JavaScript action.
export const FORMAT = 13;					// < F, field is to be formatted, JavaScript action.
export const VALIDATE = 14;				// < V, field is to be validated, JavaScript action.
export const CALCULATE = 15;				// < C, recalculate value, JavaScript action.

// additional actions defined in the document catalog's dictionary.
export const CLOSE_DOCUMENT = 16;			// < WC, before closing document, JavaScript action.
export const SAVE_DOCUMENT = 17;			// < WS, before saving document, JavaScript action.
export const DOCUMENT_SAVED = 18;			// < DS, after saving document, JavaScript action.
export const PRINT_DOCUMENT = 19;			// < WP, before printing document, JavaScript action.
export const DOCUMENT_PRINTED = 20; // < DP, after printing document, JavaScript action.
